import {
    Form,
    Input,
    DatePicker,
    Button,
    Row,
    Col,
    message,
    Select,
    Divider,
    Table,
    Spin,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { debounce, throttle } from "lodash";

import { Link, NavLink, useNavigate } from "react-router-dom";
import SalesMenu from "./../SalesMenu";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import axios from "axios";
import dayjs from "dayjs";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
    DeleteOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    MinusCircleOutlined,
} from "@ant-design/icons";

const { Option } = Select;
function BatchReceipt() {
    const navigate = useNavigate();
    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const UserName = localStorage.getItem("Full_Name");
    const CompanyID = localStorage.getItem("CompanyID");

    const [loading, setLoading] = useState(false);
    const [ListOfRecords, setListOfRecords] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [ProductList, setProductList] = useState([]);
    const [OpenDate, setOpenDate] = useState(null);
    const [CustomerLoading, setCustomerLoading] = useState(false);
    const [ListOfBank, setListOfBank] = useState([]);
    const [BankMode, setBankMode] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});


    const [ListOfProducts, setListOfProducts] = useState([]);
    const [Productform] = Form.useForm();
    const [form] = Form.useForm();

    useEffect(() => {
        document.title = "Batch Invoice";
        fetchBankAccounts();
        fetchBankMode();
        try {

            axios
                .get(Config.base_url + `CustomerSupplier/GetCustomersBy/${CompanyID}`)
                .then((response) => {
                    if (response.data.status_code === 1) {
                        setListOfRecords(response.data.listofCustomers);
                        setTotalRecords(response.data.totalRecords);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            fetchProducts();
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setCustomerLoading(false);
        }
    }, []);

    const handleDateChange = (e, value) => {
        setOpenDate(value);
    };

    const handleSubmit = async (item) => {
        const fields = form.getFieldValue("users");
        setLoading(true);

        //console.log(fields.length);
        if (fields[0].customer === undefined) {
            message.error("Please add at least one receipt.");
            setLoading(false);
            return;
        }
        if (fields[0].amount === undefined) {
            message.error("Please Enter Amount.");
            setLoading(false);
            return;
        }
        if (fields[0].bank === undefined) {
            message.error("Please Select Bank.");
            setLoading(false);
            return;
        }
        const data = fields.map((item) => ({
            ...item,
            date: OpenDate || dayjs().format("YYYY-MM-DD"),
            customerAccountCode: item.customerAccountCode || "",
            customerName: item.customer,
            amount: item.amount || 0,
            total: item.amount || 0,
            unAllocatedBalance: item.amount || 0,
            mode: item.mode || "",
            field1: item.field1 || "",
            field2: item.field2 || "",
            field3: item.field3 || "",
            field4: item.field4 || "",
            fieldA: item.fieldA || "",
            fieldB: item.fieldB || "",
            fieldC: item.fieldC || "",
            fieldD: item.fieldD || "",
            notes: item.notes || "",
            refNo: item.refNo || "",
            receiptType: "Receipt",
            receiptBy: UserName,
            extra2: "",
            companyID: CompanyID,
            isActive: true,
            isDeleted: false,
            mailingAddress: item.mailingAddress || "",
            inComplete: false,
        }));
        //console.log(data);

        try {
            const response = await axios.post(
                Config.base_url + `ReceiptHead/AddReceiptHeadArray`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );

            if (response.data.status_code === 1) {

                const receipt = response.data.receiptHeads;
                try {
                    const updateCustomerData = fields.map((item, index) => ({
                        ...item.customerData,
                        customerOpeningBalance:
                            parseFloat(item.balance) -
                            parseFloat(response.data.receiptHeads[index].amount),
                    }));
                    await axios.patch(
                        Config.base_url +
                        `CustomerSupplier/UpdateRecords`,
                        updateCustomerData,
                        {
                            headers: {
                                Authorization: `Bearer ${AccessKey}`,
                            },
                        }
                    );

                    message.success(
                        <>
                            {
                                receipt.map((item) => (
                                    <div className="alert-head">
                                        <div className="alert-body">
                                            Receipt Added Successfully Against <br />
                                            Voucher No: <span style={{ color: "blue" }}>{item.voucherNo}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    );
                    setProductList([]);
                    setLoading(false);
                    navigate("/sales/sales-receipts");
                    form.resetFields();
                    Productform.resetFields();
                } catch (error) {
                    console.error(error);
                    message.error("Error in Adding Sale Receipt");
                    setLoading(false);
                }
            };
        }
        catch (error) {
            console.error(error);
            message.error("Error in Adding Sale Receipt");
            setLoading(false);
        }
    }

    const fetchBankAccounts = async () => {
        try {
            const response = await axios.get(
                Config.base_url +
                `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (response.data.status_code === 1) {
                setListOfBank(response.data.listofAccounts);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchBankMode = async () => {
        try {
            const response = await axios.get(
                Config.base_url + `DropdownData/GetDropdownData/0?Type=BankMode`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (response.data.status_code === 1) {
                setBankMode(response.data.dropdownData || []);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchProducts = async () => {
        const api_config = {
            method: "get",
            url: `${Config.base_url}Product/GetBy/${CompanyID}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${AccessKey}`,
            },
        };

        try {
            const response = await axios(api_config);
            if (response.data && response.data.status_code === 1) {
                setListOfProducts(response.data.listofProducts || []);
            } else {
                setListOfProducts([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setListOfProducts([]);
        }
    };

    const handleCustomerChange = (value, index) => {
        const fields = form.getFieldValue("users");

        let code = value.match(/\((\d+)/);
        code = code ? code[1] : null;
        const customer = ListOfRecords.find(
            (record) =>
                record.accountNo === code
        );
        setSelectedCustomer(customer);
        if (customer) {

            fields[index] = {
                ...fields[index],
                customerAccountCode: customer.accountCode,
                balance: customer.customerOpeningBalance,
                customerData: customer,
            };
            form.setFieldsValue({
                users: fields,
            });
        }
    };

    const columns = (remove) => [
        { title: "Date", dataIndex: "date", key: "date", },
        { title: "Customer", dataIndex: "customer", key: "customer", },
        { title: "Bank", dataIndex: "bank", key: "bank", },
        {
            title: "Mode",
            dataIndex: "mode",
            key: "mode",
        },
        { title: "Ref. No.", dataIndex: "refNo", key: "refNo", },
        { title: "Amount", dataIndex: "amount", key: "amount", },
        { title: "Notes", dataIndex: "notes", key: "notes", },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, { key }) => (
                <ul className="inline-action">
                    <li>
                        <Link to={`#/`} onClick={() => remove(key)} className="red">
                            <DeleteOutlined />
                        </Link>
                    </li>
                </ul>
            ),
        },
    ];

    return (
        <>
            <div id="sub-menu-wrap">
                <h5>Sale Batch Receipts</h5>
                {/* <SalesMenu /> */}
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header"><SubMenuToggle />
                        <h3 className="page-title">
                            <NavLink to="/sales/sales-receipts">
                                <ArrowLeftIcon />
                            </NavLink>
                            Batch Receipts
                        </h3>
                    </div>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        name="dynamic_form_nest_item"
                        autoComplete="off"
                        initialValues={{
                            users: [{}],
                        }}
                    >
                        <Form.List name="users">
                            {(fields, { add, remove }) => (
                                <>
                                    <Table
                                        dataSource={fields.map(
                                            ({ key, name, fieldKey, ...restField }, index) => ({
                                                key,
                                                date: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "date"]}
                                                        fieldKey={[fieldKey, "date"]}
                                                    >
                                                        <DatePicker
                                                            style={{ minWidth: "110px" }}
                                                            defaultValue={
                                                                OpenDate === null
                                                                    ? dayjs()
                                                                    : dayjs(OpenDate, "YYYY-MM-DD")
                                                            }
                                                            onChange={handleDateChange}
                                                        />
                                                    </Form.Item>
                                                ),
                                                customerData: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "customerData"]}
                                                        fieldKey={[fieldKey, "customerData"]}
                                                        hidden
                                                    >
                                                        <Input onFocus={(e) => e.target.select()} placeholder="Doc No" variant="borderless" />
                                                    </Form.Item>
                                                ),
                                                balance: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "balance"]}
                                                        fieldKey={[fieldKey, "balance"]}
                                                        hidden
                                                    >
                                                        <Input onFocus={(e) => e.target.select()} placeholder="Doc No" variant="borderless" />
                                                    </Form.Item>
                                                ),
                                                customer: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "customer"]}
                                                        fieldKey={[fieldKey, "customer"]}
                                                        style={{ width: "200px" }}
                                                        rules={[{
                                                            required: true,
                                                            message: "Please Select Customer"
                                                        }]}
                                                    >

                                                        <Select
                                                            variant="borderless"
                                                            placeholder="Customer"
                                                            loading={CustomerLoading}
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            notFoundContent={
                                                                CustomerLoading ? <Spin size="small" /> : null
                                                            }
                                                            options={ListOfRecords.map((record) => ({
                                                                label: `${record.businessName.trim()} (${record.isSupplier && parseInt(record.accountCode) < 9000
                                                                    ? record.accountNo + ' (S)'
                                                                    : record.isCustomer && parseInt(record.accountCode) > 9000
                                                                        ? record.accountNo + ' (C)'
                                                                        : record.accountNo
                                                                    })`.trim(),
                                                                value: `${record.businessName.trim()} (${record.isSupplier && parseInt(record.accountCode) < 9000
                                                                    ? record.accountNo + ' (S)'
                                                                    : record.isCustomer && parseInt(record.accountCode) > 9000
                                                                        ? record.accountNo + ' (C)'
                                                                        : record.accountNo
                                                                    })`.trim(),
                                                            }))}
                                                            onSelect={(value) => {
                                                                handleCustomerChange(value, index);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                ),
                                                bank: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "bank"]}
                                                        fieldKey={[fieldKey, "bank"]}
                                                        rules={[{
                                                            required: true,
                                                            message: "Please Select Bank"
                                                        }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            style={{ width: 200 }}
                                                            placeholder="Bank"
                                                            variant="borderless"
                                                            options={ListOfBank.map((fieldThreeitem) => ({
                                                                label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                                                value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                ),

                                                mode: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "mode"]}
                                                        fieldKey={[fieldKey, "mode"]}
                                                        initialValue="Cash"
                                                    >
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.value.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            style={{ width: "90px" }}
                                                            placeholder="Mode"
                                                            variant="borderless"
                                                            options={BankMode.map((fieldThreeitem) => ({
                                                                label: fieldThreeitem.name,
                                                                value: fieldThreeitem.name,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                ),
                                                refNo: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "refNo"]}
                                                        fieldKey={[fieldKey, "refNo"]}
                                                    >
                                                        <Input onFocus={(e) => e.target.select()} placeholder="Ref. No." variant="borderless" />
                                                    </Form.Item>
                                                ),
                                                customerAccountCode: (
                                                    <Form.Item
                                                        name="customerAccountCode"
                                                        label="Term Days"
                                                        hidden
                                                    >
                                                        <Input onFocus={(e) => e.target.select()} />
                                                    </Form.Item>
                                                ),
                                                amount: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "amount"]}
                                                        fieldKey={[fieldKey, "amount"]}
                                                        rules={[{
                                                            required: true,
                                                            message: "Please Enter Amount"
                                                        }]}
                                                    >
                                                        <Input
                                                            placeholder="Amount"
                                                            variant="borderless"
                                                        />
                                                    </Form.Item>
                                                ),

                                                notes: (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "notes"]}
                                                        fieldKey={[fieldKey, "notes"]}
                                                    >
                                                        <Input
                                                            placeholder="Notes"
                                                            variant="borderless"
                                                        />
                                                    </Form.Item>
                                                ),
                                            })
                                        )}
                                        size="small"
                                        columns={columns(remove)}
                                        pagination={false}
                                    />

                                    <Form.Item>
                                        <Button
                                            style={{ marginTop: 10 }}
                                            type="dashed"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                        >
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item>
                            <Row justify="end">
                                <Col xs={24} md={{ span: 4, offset: 20 }}>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Approve and Close
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        </>
    );
}

export default BatchReceipt;
