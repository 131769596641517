import React, { useState, useRef, useEffect, useCallback } from "react";
import { debounce, throttle } from "lodash";
import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Divider,
  Space,
  Collapse,
  Dropdown,
  Spin,
  Flex,
  Skeleton,
  Table,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import SalesMenu from "./../SalesMenu";
import AddCustomerModal from "../../Common/AddCustomerModal";
import Typography from "antd/es/typography/Typography";

const { Option } = Select;

const EditSaleInvoice = () => {
  const params = useParams();
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserName = localStorage.getItem("Full_Name");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [overallDiscount, setOverallDiscount] = useState(0);

  const [Productform] = Form.useForm();
  const [MainForm] = Form.useForm();
  const [ProductList, setProductList] = useState([]);

  const debouncedHandleFormChange = useCallback(
    debounce(() => {
      const fields = form.getFieldValue("users");
      const amounts = fields.map((item) => parseFloat(item.amount) || 0);
      const discount = fields.map((item) => parseFloat(item.discount) || 0);
      const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
      const net = fields.map((item) => parseFloat(item.net) || 0);

      const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
      const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
      const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
      const total = net.reduce((sum, value) => sum + value, 0);

      setTotalDiscount(totalAmount);
      CustomerForm.setFieldsValue({
        subTotal: totalAmount,
        totalDiscount: totalDiscount,
        totalSaleTax: totalSaleTax,
        total: total,
      });
    }, 1000),
    []
  );

  const handleDeleteProducts = async (index) => {
    //console.log(index);
    const fields = form.getFieldValue("users");
    //console.log(fields);
    const formInstance = fields[index];

    if (!formInstance) {
      return;
    }
    const accountToUpdate = ProductList.find((u) => u.id === formInstance.id);
    //console.log("Product Found:", accountToUpdate);

    if (!accountToUpdate) {
      return;
    }

    const updatedAccount = {
      ...accountToUpdate,
      isActive: false,
      isDeleted: true,
    };

    await axios.patch(
      `${Config.base_url}SaleBody/DeleteRecord/${formInstance.id}`,
      updatedAccount,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      }
    );
    fetchProductData();
    debouncedHandleFormChange();
  };

  const [loading, setLoading] = useState(false);
  const [CustomerLoading, setCustomerLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [CustomerID, setCustomerID] = useState("");
  const [open, setOpen] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalSaleTax, setTotalSaleTax] = useState(0);

  const [CustomerForm] = Form.useForm();
  const [formMain] = Form.useForm();
  const [OpenDate, setOpenDate] = useState(null);
  const [termDays, setTermDays] = useState("");
  const [dueDate, setDueDate] = useState(null);

  const [ListOfRecords, setListOfRecords] = useState([]);
  const [ListOfProducts, setListOfProducts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customer, setCustomer] = useState([]);
  const [product, setProduct] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [LoadingCustomerData, setLoadingCustomerData] = useState(false);
  const [LoadingProductData, setLoadingProductData] = useState(false);

  const [form] = Form.useForm();
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [receiptHeadData, setReceiptHeadData] = useState({});
  const [Complete, setComplete] = useState(false);

  useEffect(() => {
    fetchCustomer();
    fetchProducts();
  }, []);

  const handleSubmit = async (FormData) => {
    setLoading(true);
    if (!FormData.total) {
      message.error("Please enter valid invoice data");
      setLoading(false);
      return;
    }

    if (FormData.total < paymentData.receipt) {
      message.error(
        "Invoice Total should be greater than alredy paid Balace i.e. " +
        paymentData.receipt
      );
      setLoading(false);
      return;
    }
    const fields = form.getFieldValue("users");
    if (fields.length === 0) {
      message.error("Please add at least one product.");
      setLoading(false);
      return;
    }

    const CustomerData = {
      ...customer,
      ...FormData,
      date: OpenDate || dayjs().format("YYYY-MM-DD"),
      dueDate: dueDate || dayjs().format("YYYY-MM-DD"),
      field1: FormData.field1 || "",
      field2: FormData.field2 || "",
      field3: FormData.field3 || "",
      overallDiscount: FormData.overallDiscount || 0.0,
      fieldA: FormData.fieldA || "",
      fieldB: FormData.fieldB || "",
      fieldC: FormData.fieldC || "",
      fieldD: FormData.fieldD || "",
      notes: FormData.notes || "",
      docNo: FormData.docNo || "",

      balance: (() => {
        let calculatedBalance = receiptHeadData.amount
          ? parseFloat(FormData.total || 0) -
          parseFloat(receiptHeadData.amount || 0)
          : parseFloat(FormData.total || 0);
        return calculatedBalance < 0 ? 0 : calculatedBalance;
      })(),
      saleType: "Invoice",
      saleBy: UserName,
      extra2: "",
      companyID: CompanyID,
      isActive: true,
      isDeleted: false,
      inComplete: Complete,
    };

    try {
      const saleResponse = await axios.patch(
        `${Config.base_url}SaleHead/UpdateRecord/${customer.id}`,
        CustomerData,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (saleResponse.data.status_code === 1) {
        //console.log(saleResponse.data);
        const productData = fields.map((item) => ({
          ...item,
          defaultUnit: item.defaultUnit || "",
          field1: item.field1 || "",
          field2: item.field2 || "",
          weight: item.weight || 0,
          length: item.length || 0,
          quantity: item.quantity || 0,
          saleType: "Invoice",
          saleBy: UserName,
          companyID: CompanyID,
          isActive: true,
          isDeleted: false,
          inComplete: Complete,
        }));

        const productResponse = await axios.patch(
          `${Config.base_url}SaleBody/UpdateRecords/${params.id}`,
          productData,
          {
            headers: {
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );
        if (productResponse.data.status_code === 1) {
          const updateCustomerData = {
            ...selectedCustomer,
            customerOpeningBalance:
              selectedCustomer.customerOpeningBalance +
              FormData.total -
              customer.total,
          };
          //console.log(updateCustomerData);
          await axios.patch(
            Config.base_url +
            `CustomerSupplier/UpdateRecord/${selectedCustomer.id}`,
            updateCustomerData,
            {
              headers: {
                Authorization: `Bearer ${AccessKey}`,
              },
            }
          );

          const updateProduct = fields.map((item) => ({
            ...item,
            name: FormData.name || "",
            type: FormData.type || "",
            date: OpenDate || dayjs().format("YYYY-MM-DD"),
            cost: FormData.cost || 0,
            unit: FormData.unit || "",
            notes: FormData.notes || "",
            weight: FormData.weight || 0,
            field1: FormData.field1 || "",
            field2: FormData.field2 || "",
            field3: FormData.field3 || "",
            field4: FormData.field4 || "",
            fieldA: FormData.fieldA || "",
            fieldB: FormData.fieldB || "",
            fieldC: FormData.fieldC || "",
            fieldD: FormData.fieldD || "",
            category: FormData.category || "",
            maxRRExTax: FormData.maxRRExTax || 0,
            salePrice: FormData.salePrice || 0,
            openingRate: FormData.openingRate || 0,
            saleDiscount: FormData.saleDiscount || 0,
            stockAssetAccount: FormData.stockAssetAccount || "",
            lowStockLevel: FormData.lowStockLevel || 0,
            incomeAccount: FormData.incomeAccount || "",
            expenseAccount: FormData.expenseAccount || "",
            purchaseDiscount: FormData.purchaseDiscount || 0,
            gstRate: FormData.gstRate || "",
            saleInformation: FormData.saleInformation || "",
            nonFilerGSTRate: FormData.nonFilerGSTRate || "",
            maxRRIncTax: FormData.maxRRIncTax || 0,
            binLocation: FormData.binLocation || "",
            largePackSize: FormData.largePackSize || 0,
            smallPackSize: FormData.smallPackSize || 0,
            prefferedSupplier: FormData.prefferedSupplier || "",
            productType: "Stock",
            size: FormData.size || "",
            companyID: CompanyID,
            defaultUnit: "",
            id: item.selectedProduct,
            openingQuantity:
              parseFloat(item.openingQuantity -
                (item.defaultUnit === "Quantity"
                  ? item.quantity
                  : item.defaultUnit === "Weight"
                    ? item.weight
                    : item.length) +
                (item.defaultUnit === "Quantity"
                  ? item.BaseQuantity
                  : item.defaultUnit === "Weight"
                    ? item.BaseWeight
                    : item.BaseLength)),
            isActive: true,
            isDeleted: false,
            categoryCode: "",
          }));
          console.log(updateProduct);
          await axios.patch(
            Config.base_url + `Product/UpdateRecords`,
            updateProduct,
            {
              headers: {
                Authorization: `Bearer ${AccessKey}`,
              },
            }
          );

          if (paymentData.receipt) {
            try {
              const PaymentData = {
                ...FormData,
                ...receiptHeadData,
                totalDiscount: FormData.totalDiscount,
                totalOpenBalance: FormData.total,
                date: OpenDate || dayjs().format("YYYY-MM-DD"),
                receiptType: "Receipt",
                receiptBy: UserName,
                extra2: "",
                companyID: CompanyID,
                isActive: true,
                isDeleted: false,
                mailingAddress: FormData.address || "",
              };

              const paymentResponse = await axios.patch(
                `${Config.base_url}ReceiptHead/UpdateRecord/${receiptHeadData.id}`,
                PaymentData,
                {
                  headers: {
                    Authorization: `Bearer ${AccessKey}`,
                  },
                }
              );

              if (paymentResponse.data.status_code === 1) {
                const paymentBodyData = [
                  {
                    ...paymentData,
                    discount: FormData.totalDiscount,
                    openBalance: FormData.total,
                    receipt: paymentData.receipt,
                    amount: FormData.total,
                    total: paymentData.receipt,
                    date: OpenDate || dayjs().format("YYYY-MM-DD"),
                    dueDate: dueDate || dayjs().format("YYYY-MM-DD"),
                    docNo: FormData.docNo || "",
                    field1: FormData.field1 || "",
                    field2: FormData.field2 || "",
                    field3: FormData.field3 || "",
                    field4: FormData.field4 || "",
                    receiptType: "Receipt",
                    receiptBy: UserName,
                    companyID: CompanyID,
                    isActive: true,
                    isDeleted: false,
                  },
                ];

                const ProductResponse = await axios.patch(
                  Config.base_url +
                  `ReceiptBody/UpdateRecords/${paymentData.id}`,
                  paymentBodyData,
                  {
                    headers: {
                      Authorization: `Bearer ${AccessKey}`,
                    },
                  }
                );
                if (ProductResponse.data.status_code === 1) {
                  message.success("Invoice Updated Successfully");
                  setProductList([]);
                  setLoading(false);
                  navigate("/sales/sales-invoices");
                  CustomerForm.resetFields();
                  Productform.resetFields();
                } else {
                  message.error("Error in Updating Payment");
                  setLoading(false);
                }
              } else {
                message.error("Error in Updating Payment");
                setLoading(false);
              }
            } catch (error) {
              console.error(error);
              message.error("Error in Updating Payment");
              setLoading(false);
            }
          } else {
            message.success("Invoice Updated Successfully");
            setProductList([]);
            setLoading(false);
            navigate("/sales/sales-invoices");
            CustomerForm.resetFields();
            Productform.resetFields();
          }
        } else {
          message.error("Error in Updating Product Data");
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      message.error("Error in Updating Sale Head");
      setLoading(false);
    }
  };

  const fetchCustomerData = async () => {
    setLoadingCustomerData(true);
    try {
      const { data } = await axios.get(
        `${Config.base_url}SaleHead/GetSaleHeadBy/${params.id}/${CompanyID}`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (data.status_code === 1) {
        // Set form values and date
        CustomerForm.setFieldsValue(data.saleHeadData);
        setComplete(data.saleHeadData.inComplete);
        debouncedHandleFormChange();
        if (CompanyID != data.saleHeadData.companyID) {
          navigate("/sales/sales-invoices");
        }
        const code = data.saleHeadData.customerAccountCode;
        const name = data.saleHeadData.customerName.match(/^[^\(]+/)[0].trim();
        const customerData = await axios.get(
          `${Config.base_url}CustomerSupplier/GetCustomersBy/${CompanyID}?accountCode=${code}`,
          {
            headers: {
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );
        if (customerData.data.status_code == 1) {
          //console.log(customerData.data.listofCustomers[0]);
          setSelectedCustomer(customerData.data.listofCustomers[0]);
        }
        setCreatedDate(data.saleHeadData.createdDate);
        setCreatedBy(data.saleHeadData.saleBy);
        setTermDays(data.saleHeadData.termDays);
        const productDate = CustomerForm.getFieldValue("date");
        const DueDate = CustomerForm.getFieldValue("dueDate");
        setOpenDate(productDate);
        setDueDate(DueDate);
        setCustomer(data.saleHeadData);
        console.log(data.saleHeadData);
        setTotalAmount(data.saleHeadData.subTotal);
      }
    } catch (error) {
      console.error("Error fetching salehead data:", error);
    } finally {
      setLoadingCustomerData(false); // Stop loading
    }
  };

  const formatDate = (isoDateString) => {
    const [datePart] = isoDateString.split("T");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (ListOfProducts.length > 0 && ProductList.length > 0) {
      // Once both ListOfProducts and productList are available, loop through and fetch the selected products
      ProductList.forEach((user, index) => {
        handleFetchProduct(user.product, index);
        const fields = form.getFieldValue("users");

        fields[index] = {
          ...fields[index],
          BaseWeight: user.weight || 0,
          BaseQuantity: user.quantity || 0,
          BaseLength: user.length || 0,
        };

        form.setFieldsValue({
          users: fields,
        });
      });
    }
  }, [ListOfProducts, ProductList]);

  const fetchProductData = async () => {
    setLoadingProductData(true);
    try {
      const { data } = await axios.get(
        `${Config.base_url}SaleBody/GetSaleBodyBy/${params.id}/${CompanyID}`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (data.status_code === 1) {
        setProductList(data.saleBodyData); // Update the product list state
        setProduct(data.saleBodyData);
        form.setFieldsValue({ users: data.saleBodyData });
      }
    } catch (error) {
      console.error("Error fetching salebody data:", error);
    } finally {
      setLoadingProductData(false);
    }
  };

  const handleFetchProduct = (value, index) => {
    //console.log(value);
    const selectedProduct = ListOfProducts.find((item) => item.name === value);
    //console.log(selectedProduct);
    if (selectedProduct) {
      const fields = form.getFieldValue("users");
      const quantity = fields[index].quantity || 0;
      const weight = fields[index].quantity || 0;
      const length = fields[index].quantity || 0;
      const rate = selectedProduct.salePrice;
      const amount = (quantity * rate).toFixed(2);
      const discount = (amount * selectedProduct.saleDiscount) / 100;
      const saleTax = (selectedProduct.gstRate * amount) / 100;
      const net = (amount - discount + saleTax).toFixed(2);

      form.setFieldsValue({
        users: fields.map((field, i) =>
          i === index
            ? {
              ...field,
              defaultUnit: selectedProduct.defaultUnit,
              openingQuantity: selectedProduct.openingQuantity,
              selectedProduct: selectedProduct.id,
            }
            : field
        ),
      });
      debouncedHandleFormChange();
    } else {
      console.error("Selected product not found in ListOfProducts:", value);
    }
  };

  const fetchPaymentData = async () => {
    try {
      const { data } = await axios.get(
        `${Config.base_url}ReceiptBody/GetReceiptBodyDataBy/${params.id}/${CompanyID}`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (data.status_code === 1) {
        setPaymentData(data.saleBodyData);
        const receiptHeadData = await axios.get(
          `${Config.base_url}ReceiptHead/GetReceiptHeadBy/${data.saleBodyData.voucherNo}/${CompanyID}`,
          {
            headers: {
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );

        setReceiptHeadData(receiptHeadData.data.saleHeadData);
      }
    } catch (error) {
      console.error("Error fetching salebody data:", error);
    }
  };

  useEffect(() => {
    document.title = "Edit Sale Invoice";
    fetchCustomer();
    fetchProducts();
    fetchCustomerData();
    fetchProductData();
    fetchPaymentData();
  }, []);

  const fetchProducts = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}Product/GetBy/${CompanyID}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setProductLoading(false);
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setProductLoading(false);

        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setProductLoading(false);

      setListOfProducts([]);
    }
  };

  const fetchCustomer = async () => {
    // setCustomerLoading(true)
    const api_config = {
      method: "get",
      url: `${Config.base_url}CustomerSupplier/GetCustomersBy/${CompanyID}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);

      if (response.data && response.data.status_code === 1) {
        setListOfRecords(response.data.listofCustomers || []);
        setTotalRecords(response.data.totalRecords || 0);
        // setCustomerLoading(false)
      } else {
        console.warn(
          "No data or error status_code:",
          response.data.status_code
        );
        // setCustomerLoading(false)
        setListOfRecords([]);
      }
    } catch (error) {
      // setCustomerLoading(false)
      console.error(
        "Error fetching data:",
        error.response?.data || error.message
      );
      setListOfRecords([]);
    }
  };

  const handleOk = (FormData) => {
    setLoading(true);
    setOpen(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const CustomerDetail = () => {
    const onTermDaysChange = (e) => {
      const value = e.target.value;

      setTermDays(value);
      const days = parseInt(value, 10);
      if (!isNaN(days)) {
        const newDueDate = dayjs().add(days, "day").format("YYYY-MM-DD");
        //console.log(newDueDate);
        setDueDate(newDueDate);
        setTermDays(days);
      } else {
        setTermDays(0);
      }
    };

    const handleCustomerChange = (value) => {
      let code = value.match(/\((\d+)/);
      code = code ? code[1] : null;
      const customer = ListOfRecords.find(
        (record) => record.accountNo === code
      );
      setSelectedCustomer(customer);
      //console.log(customer)
      if (customer) {
        CustomerForm.setFieldsValue({
          address: customer.billingAddress,
          creditLimit: customer.creditLimit,
          balance: customer.customerOpeningBalance,
          CustomerAccountCode: customer.accountNo,
        });
      }
    };

    const handleDateChange = (e, value) => {
      setOpenDate(value);
    };

    const handleDueDateChange = (e, value) => {
      setDueDate(value);
    };

    return (
      <>
        <Form layout="vertical" form={CustomerForm} onFinish={handleSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="customerName"
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: "Please select the customer name.",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Customer"
                  dropdownRender={(menufieldone) => (
                    <>
                      {menufieldone}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={() => setOpen(true)}
                        >
                          Add Field
                        </Button>
                      </Space>
                    </>
                  )}
                  loading={CustomerLoading}
                  notFoundContent={
                    CustomerLoading ? <Spin size="small" /> : null
                  }
                  options={ListOfRecords.map((record) => ({
                    label: `${record.businessName.trim()} (${record.isSupplier && parseInt(record.accountCode) < 9000
                      ? record.accountNo + " (S)"
                      : record.isCustomer &&
                        parseInt(record.accountCode) > 9000
                        ? record.accountNo + " (C)"
                        : record.accountNo
                      })`.trim(),
                    value: `${record.businessName.trim()} (${record.isSupplier && parseInt(record.accountCode) < 9000
                      ? record.accountNo + " (S)"
                      : record.isCustomer &&
                        parseInt(record.accountCode) > 9000
                        ? record.accountNo + " (C)"
                        : record.accountNo
                      })`.trim(),
                  }))}
                  onSelect={handleCustomerChange}
                />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item label="Date" required>
                    <DatePicker
                      defaultValue={OpenDate ? dayjs(OpenDate, "YYYY-MM-DD") : dayjs()}
                      style={{ width: "100%" }}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                </Col>
                <Form.Item name="CustomerAccountCode" label="Term Days" hidden>
                  <Input />
                </Form.Item>
                <Col xs={24} md={12}>
                  <Form.Item name="termDays" label="Term Days">
                    <Input type="number" onChange={onTermDaysChange} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Due Date" required>
                    <DatePicker
                      defaultValue={dayjs(OpenDate).add(termDays, "days")}
                      style={{ width: "100%" }}
                      onChange={handleDueDateChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="docNo" label="Doc No.">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24}>
                  <Form.Item name="creditLimit" label="Credit Limit">
                    <Input readOnly disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item name="balance" label="Balance">
                    <Input readOnly disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const ProductDetail = () => {
    const handleSelectChange = (value, index) => {
      const selectedProduct = ListOfProducts.find(
        (item) => item.name === value
      );
      //console.log(selectedProduct);
      if (selectedProduct) {
        const fields = form.getFieldValue("users");
        const quantity = fields[index].quantity || 0;
        const weight = fields[index].quantity || 0;
        const length = fields[index].quantity || 0;
        const rate = selectedProduct.salePrice;
        const amount = (quantity * rate).toFixed(2);
        const discount = (amount * selectedProduct.saleDiscount) / 100;
        const saleTax = (selectedProduct.gstRate * amount) / 100;
        const net = (amount - discount + saleTax).toFixed(2);

        form.setFieldsValue({
          users: fields.map((field, i) =>
            i === index
              ? {
                ...field,
                description: `${selectedProduct.name} ${selectedProduct.saleInformation}`,
                unit: selectedProduct.unit,
                weight,
                length,
                quantity,
                rate,
                amount,
                discPercentege: selectedProduct.saleDiscount,
                discount,
                taxRate: selectedProduct.gstRate,
                saleTax,
                net,
                defaultUnit: selectedProduct.defaultUnit,
                openingQuantity: selectedProduct.openingQuantity,
                selectedProduct: selectedProduct.id,
              }
              : field
          ),
        });
        debouncedHandleFormChange();
      } else {
        console.error("Selected product not found in ListOfProducts:", value);
      }
    };

    const handleQuantityChange = (e, index) => {
      const quantity = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(quantity) || quantity <= 0) {
        //console.log("Invalid quantity value");
        return;
      }

      if (formInstance.defaultUnit === "Quantity") {
        const amount = (quantity * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          quantity,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Quantity");
      }
    };

    const handleWeightChange = (e, index) => {
      const weight = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(weight) || weight <= 0) {
        //console.log("Invalid weight value");
        return;
      }

      if (formInstance.defaultUnit === "Weight") {
        const amount = (weight * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          weight,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Weight");
      }
    };

    const handleLengthChange = (e, index) => {
      const length = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(length) || length <= 0) {
        //console.log("Invalid length value");
        return;
      }

      if (formInstance.defaultUnit === "Length") {
        const amount = (length * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          length,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Length");
      }
    };

    const handleRateChange = (e, index) => {
      const fields = form.getFieldValue("users");

      const formInstance = fields[index];
      const rate = parseFloat(e.target.value) || 0;
      const quantity = parseFloat(formInstance.quantity) || 1;
      const length = parseFloat(formInstance.length) || 1;
      const weight = parseFloat(formInstance.weight) || 1;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      if (formInstance.defaultUnit === "Length") {
        const amount = length * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else if (formInstance.defaultUnit === "Weight") {
        const amount = weight * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else if (formInstance.defaultUnit === "Quantity") {
        const amount = quantity * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const handleDiscountChange = (e, index) => {
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      //console.log(formInstance);

      const discountPercentage = parseFloat(e.target.value) || 0;
      if (discountPercentage > 100) {
        message.error("Discount cannot be more than 100.");
        return;
      }

      // Ensure amount is not recalculated here
      const amount = parseFloat(formInstance.amount) || 0;
      const gst = parseFloat(formInstance.saleTax) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const net = (amount - discountAmt + gst).toFixed(2);

        fields[index] = {
          ...fields[index],
          discount: discountAmt,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        console.error("Invalid amount");
      }
    };

    const handleTaxRateChange = (e, index) => {
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      const taxRate = parseFloat(e.target.value) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;
      const quantity = parseFloat(formInstance.quantity) || 0;
      const rate = parseFloat(formInstance.rate) || 0;
      const amount = parseFloat(formInstance.amount) || 0; // Get the existing amount

      if (quantity > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const taxAmt = (taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + taxAmt).toFixed(2);

        fields[index] = {
          ...fields[index],
          saleTax: taxAmt,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const handleUnitChange = (value, index) => {
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];
      const rate = parseFloat(formInstance.rate) || 0;
      const quantity = parseFloat(formInstance.quantity) || 0;
      const length = parseFloat(formInstance.length) || 0;
      const weight = parseFloat(formInstance.weight) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      let amount = 0;
      let discountAmt = 0;
      let tax = 0;
      let net = 0;

      if (value === "Length") {
        amount = length * rate;
      } else if (value === "Weight") {
        amount = weight * rate;
      } else if (value === "Quantity") {
        amount = quantity * rate;
      }

      discountAmt = (discountPercentage * amount) / 100 || 0;
      tax = (formInstance.taxRate * amount) / 100 || 0;
      net = (amount - discountAmt + tax).toFixed(2);

      fields[index] = {
        ...fields[index],
        amount,
        discount: discountAmt,
        saleTax: tax,
        net,
      };

      form.setFieldsValue({
        users: fields,
      });

      debouncedHandleFormChange(); // Trigger the form change handler after setting values
    };

    const columns = (remove) => [
      {
        title: "Product / Services",
        dataIndex: "product",
        key: "product",
        width: 150,
      },

      { title: "Unit", dataIndex: "unit", key: "unit", width: 150 },
      { title: "Quantity", dataIndex: "quantity", key: "quantity", width: 100 },
      { title: "Weight", dataIndex: "weight", key: "weight", width: 100 },
      { title: "Length", dataIndex: "length", key: "length", width: 100 },
      { title: "Rate", dataIndex: "rate", key: "rate", width: 230 },
      {
        title: "Rate Unit",
        dataIndex: "defaultUnit",
        key: "defaultUnit",
        width: 180,
      },
      { title: "Amount", dataIndex: "amount", key: "amount", width: 250 },
      // {
      //   title: "Disc %",
      //   dataIndex: "discPercentege",
      //   key: "discPercentege",
      //   width: 100,
      // },
      // { title: "Discount", dataIndex: "discount", key: "discount", width: 150 },
      // { title: "Tax Rate %", dataIndex: "taxRate", key: "taxRate", width: 150 },
      // { title: "Sale Tax", dataIndex: "saleTax", key: "saleTax", width: 200 },
      { title: "Net ", dataIndex: "net", key: "net", width: 250 },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link
                to={`#/`}
                onClick={() => {
                  handleDeleteProducts(key);
                  remove(key);
                }}
                className="red"
              >
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];
    const debouncedHandleFormChange = useCallback(
      debounce(() => {
        const fields = form.getFieldValue("users");
        const amounts = fields.map((item) => parseFloat(item.amount) || 0);
        const discount = fields.map((item) => parseFloat(item.discount) || 0);
        const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
        const net = fields.map((item) => parseFloat(item.net) || 0);

        const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
        const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
        const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
        const total = net.reduce((sum, value) => sum + value, 0);

        setTotalDiscount(totalAmount);
        CustomerForm.setFieldsValue({
          subTotal: totalAmount,
          totalDiscount: totalDiscount,
          totalSaleTax: totalSaleTax,
          total: total,
        });
      }, 1000),
      []
    );

    return (
      <>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={handleSubmit}
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      key,
                      product: (
                        <Form.Item
                          {...restField}
                          name={[name, "product"]}
                          fieldKey={[fieldKey, "product"]}
                          style={{ width: "300px" }}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleSelectChange(value, index);
                            }}
                            placeholder="Product / Services"
                            variant="borderless"
                            options={ListOfProducts.map((record) => ({
                              label: (
                                <>
                                  {record.name} - Q : {record.openingQuantity}
                                </>
                              ),
                              value: record.name,
                            }))}
                          />
                        </Form.Item>
                      ),

                      unit: (
                        <Form.Item
                          {...restField}
                          name={[name, "unit"]}
                          fieldKey={[fieldKey, "unit"]}
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      quantity: (
                        <Form.Item
                          {...restField}
                          name={[name, "quantity"]}
                          fieldKey={[fieldKey, "quantity"]}
                        >
                          <Input
                            placeholder="Quantity"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleQuantityChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      weight: (
                        <Form.Item
                          {...restField}
                          name={[name, "weight"]}
                          fieldKey={[fieldKey, "weight"]}
                        >
                          <Input
                            placeholder="Weight"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleWeightChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      length: (
                        <Form.Item
                          {...restField}
                          name={[name, "length"]}
                          fieldKey={[fieldKey, "length"]}
                        >
                          <Input
                            placeholder="Length"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleLengthChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      rate: (
                        <Form.Item
                          {...restField}
                          name={[name, "rate"]}
                          fieldKey={[fieldKey, "rate"]}
                        >
                          <Input
                            placeholder="Rate"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleRateChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      defaultUnit: (
                        <Form.Item
                          {...restField}
                          name={[name, "defaultUnit"]}
                          fieldKey={[fieldKey, "defaultUnit"]}
                        >
                          <Select
                            variant="borderless"
                            placeholder="Rate Unit"
                            onChange={(value) => {
                              handleUnitChange(value, index); // Pass the selected value directly
                            }}
                          >
                            <Option value="Quantity">Quantity</Option>
                            <Option value="Weight">Weight</Option>
                            <Option value="Length">Length</Option>
                          </Select>
                        </Form.Item>
                      ),
                      amount: (
                        <Form.Item
                          {...restField}
                          name={[name, "amount"]}
                          fieldKey={[fieldKey, "amount"]}
                        >
                          <Input
                            placeholder="Amount"
                            readOnly
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      // discPercentege: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "discPercentege"]}
                      //     fieldKey={[fieldKey, "discPercentege"]}
                      //     onChange={(e) => handleDiscountChange(e, index)}
                      //   >
                      //     <Input placeholder="Disc %" variant="borderless" />
                      //   </Form.Item>
                      // ),
                      // discount: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "discount"]}
                      //     fieldKey={[fieldKey, "discount"]}
                      //   >
                      //     <Input placeholder="Discount" variant="borderless" />
                      //   </Form.Item>
                      // ),
                      // taxRate: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "taxRate"]}
                      //     fieldKey={[fieldKey, "taxRate"]}
                      //     onChange={(e) => handleTaxRateChange(e, index)}
                      //   >
                      //     <Input
                      //       placeholder="Tax Rate %"
                      //       variant="borderless"
                      //     />
                      //   </Form.Item>
                      // ),
                      // saleTax: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "saleTax"]}
                      //     fieldKey={[fieldKey, "saleTax"]}
                      //   >
                      //     <Input placeholder="Sale Tax" variant="borderless" />
                      //   </Form.Item>
                      // ),
                      openingQuantity: (
                        <Form.Item
                          {...restField}
                          name={[name, "openingQuantity"]}
                          fieldKey={[fieldKey, "openingQuantity"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      selectedProduct: (
                        <Form.Item
                          {...restField}
                          name={[name, "selectedProduct"]}
                          fieldKey={[fieldKey, "selectedProduct"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      BaseLength: (
                        <Form.Item
                          {...restField}
                          name={[name, "BaseLength"]}
                          fieldKey={[fieldKey, "BaseLength"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      BaseQuantity: (
                        <Form.Item
                          {...restField}
                          name={[name, "BaseQuantity"]}
                          fieldKey={[fieldKey, "BaseQuantity"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      BaseWeight: (
                        <Form.Item
                          {...restField}
                          name={[name, "BaseWeight"]}
                          fieldKey={[fieldKey, "BaseWeight"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      net: (
                        <Form.Item
                          {...restField}
                          name={[name, "net"]}
                          fieldKey={[fieldKey, "net"]}
                        >
                          <Input
                            placeholder="Net"
                            variant="borderless"
                            readOnly
                          />
                        </Form.Item>
                      ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                />
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const handleOverAllDiscountChange = (e) => {
    const discount = e.target.value;
    const subTotal = CustomerForm.getFieldValue("subTotal");
    const totalSaleTax = CustomerForm.getFieldValue("totalSaleTax");
    const totalDiscount = CustomerForm.getFieldValue("totalDiscount");

    const SubTotalAfterDiscount = (subTotal * discount) / 100 || 0;

    const netTotal = subTotal + totalSaleTax - totalDiscount - discount;

    CustomerForm.setFieldsValue({
      total: netTotal.toFixed(2),
    });
  };

  return (
    <>
      <AddCustomerModal
        show={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={CustomerLoading}
        CustomerID={CustomerID}
      />
      <div id="sub-menu-wrap">
        <h5>Sales</h5>
        <SalesMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content mb-5">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/sales/sales-invoices">
                <ArrowLeftIcon />
              </NavLink>
              Edit Sales Invoice
            </h3>
          </div>

          {!LoadingCustomerData ? (
            <>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Customer Details"]}
                  items={[
                    {
                      key: "Customer Details",
                      label: "Customer Details",
                      children: <CustomerDetail />,
                    },
                  ]}
                />
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["Product Details"]}
                  items={[
                    {
                      key: "Product Details",
                      label: "Product Details",
                      children: <ProductDetail />,
                    },
                  ]}
                />
                <Form
                  layout="vertical"
                  form={CustomerForm}
                  onFinish={handleSubmit}
                >
                  <Row gutter={[24, 0]}>
                    <Col xs={24} md={12}>
                      <Form.Item name="notes" label="Notes">
                        <Input.TextArea rows={5} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Row gutter={[24, 0]}>
                        <Col xs={24} md={12}>
                          <Form.Item name="subTotal" label="Sub Total">
                            <Input readOnly value={totalAmount} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item name="totalDiscount" label="Discount">
                            <Input readOnly value={totalDiscount} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item name="totalSaleTax" label="Sale Tax">
                            <Input readOnly defaultValue={totalSaleTax} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            name="overallDiscount"
                            label="OverAll Discount"
                            onChange={(e) => handleOverAllDiscountChange(e)}
                          >
                            <Input defaultValue={overallDiscount} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                          <h4>
                            <Form.Item
                              name="total"
                              label="Total:"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter valid data",
                                },
                              ]}
                            >
                              <Input readOnly />
                            </Form.Item>
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Space>
              <Form form={CustomerForm} onFinish={handleSubmit}>
                <Form.Item name="inComplete" valuePropName="checked">
                  <Flex justify="space-between" align="center">
                    <Typography.Text></Typography.Text>
                    <Checkbox
                      checked={Complete}
                      onChange={(e) => setComplete(!Complete)}
                    >
                      InComplete
                    </Checkbox>
                  </Flex>
                </Form.Item>
                <Form.Item>
                  <Flex justify="space-between" align="center">
                    <Typography.Text>
                      <h5>
                        Created By : {createdBy} - {formatDate(createdDate)}
                      </h5>
                    </Typography.Text>

                    <Button type="primary" htmlType="submit" loading={loading}>
                      Edit
                    </Button>
                  </Flex>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EditSaleInvoice;
