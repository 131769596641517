import {
  Form,
  Input,
  Select,
  Table,
  Button,
  Popconfirm,
  message,
  DatePicker,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  CodeSandboxOutlined,
  CaretDownFilled,
  StockOutlined,
} from "@ant-design/icons";
import { Dropdown, Space, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import ProductionMenu from "./../ProductionMenu";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import axios from "axios";
import dayjs from "dayjs";
const ExcelJS = require("exceljs");

function StockAdjustment(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [loading, setLoading] = useState(false);
  const [ListOfProducts, setListOfProducts] = useState([]);
  const [NominalAccount, setNominalAccount] = useState("");
  const [Type, setType] = useState("");
  const [date, setDate] = useState("");
  const [openDate, setOpenDate] = useState("");
  const [ListOfAccounts, setListOfAccounts] = useState([]);
  const [ListOfAccountsLoading, setListOfAccountsLoading] = useState(false);

  const [form] = Form.useForm();

  const fetchProducts = async () => {
    setLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}StockAdjustHead/GetBy/${CompanyID}?nominalAccount=${NominalAccount}&adjustType=${Type}&date=${date}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfProducts(response.data.listofStock || []);
      } else {
        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setListOfProducts([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccounts = async () => {
    setListOfAccountsLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}AccountMain/GetBy/${CompanyID}`,
      headers: {
        Authorization: `Bearer ${AccessKey}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfAccounts(response.data.listofAccounts || []);
      } else {
        setListOfAccounts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setListOfAccounts([]);
    }
    finally {
      setListOfAccountsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Stock Adjustment";
    fetchProducts();
    fetchAccounts();
  }, [date, NominalAccount, Type]);

  const handleFilters = (formData) => {
    setNominalAccount(formData["nominalAccount"] || "");
    setType(formData["adjustType"] || "");
    setDate(openDate);
  };

  const deleteStockIn = async (sno) => {
    try {
      // Find the account to update from ListOfProducts
      const accountToUpdate = ListOfProducts.find((u) => u.id === sno);
      if (!accountToUpdate) {
        message.error("Stock not found!");
        return;
      }

      // Prepare the updated account object
      const updatedAccount = {
        ...accountToUpdate,
        isActive: false,
        isDeleted: true,
      };

      // Send patch request to update the stock record
      const deleteStockResponse = await axios.patch(
        `${Config.base_url}StockAdjustHead/UpdateRecord/${sno}`,
        updatedAccount,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      // Check if the stock was successfully deleted
      if (deleteStockResponse.data.status_code !== 1) {
        message.error("Failed to delete stock.");
        return;
      }

      //console.log(deleteStockResponse.data);

      // Fetch StockAdjustBody records using invoice number
      const invoiceNo = deleteStockResponse.data.stockHead.invoiceNo;
      const fetchStockInBodyResponse = await axios.get(
        `${Config.base_url}StockAdjustBody/GetStockAdjustBody/${invoiceNo}/${CompanyID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      const stockAdjustBody = fetchStockInBodyResponse.data.stockAdjustBody;

      // Fetch all products in parallel and update them
      const updateProductRequests = stockAdjustBody.map(async (item) => {
        // Fetch product data based on product code
        const productResponse = await axios.get(
          `${Config.base_url}Product/GetBy/${CompanyID}?code=${item.productCode}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );

        const product = productResponse.data.listofProducts[0];
        if (!product) return;

        // Calculate new opening quantity based on the default unit
        const openingQuantity =
          (product.openingQuantity || 0) -
          (item.defaultUnit === "Quantity"
            ? item.quantity
            : item.defaultUnit === "Weight"
              ? item.weight
              : item.length || 0);

        // Prepare the updated product data
        const updatedProduct = [{
          ...product,
          openingQuantity,
          isActive: true,
          isDeleted: false,
        }];

        // Send patch request to update the product
        await axios.patch(`${Config.base_url}Product/UpdateRecords`, updatedProduct, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        });
      });

      // Run all update requests in parallel
      await Promise.all(updateProductRequests);

      message.success("Stock In deleted successfully.");
      fetchProducts(); // Fetch products to refresh the UI

    } catch (error) {
      console.error("Error deleting product:", error);
      message.error("An error occurred while deleting stock.");
    }
  };

  const deleteStockOut = async (sno) => {
    try {
      // Find the account to update from ListOfProducts
      const accountToUpdate = ListOfProducts.find((u) => u.id === sno);
      if (!accountToUpdate) {
        message.error("Stock not found!");
        return;
      }

      // Prepare the updated account object
      const updatedAccount = {
        ...accountToUpdate,
        isActive: false,
        isDeleted: true,
      };

      // Send patch request to update the stock record
      const deleteStockResponse = await axios.patch(
        `${Config.base_url}StockAdjustHead/UpdateRecord/${sno}`,
        updatedAccount,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      // Check if the stock was successfully deleted
      if (deleteStockResponse.data.status_code !== 1) {
        message.error("Failed to delete stock.");
        return;
      }

      //console.log(deleteStockResponse.data);

      // Fetch StockAdjustBody records using invoice number
      const invoiceNo = deleteStockResponse.data.stockHead.invoiceNo;
      const fetchStockInBodyResponse = await axios.get(
        `${Config.base_url}StockAdjustBody/GetStockAdjustBody/${invoiceNo}/${CompanyID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      const stockAdjustBody = fetchStockInBodyResponse.data.stockAdjustBody;

      // Fetch all products in parallel and update them
      const updateProductRequests = stockAdjustBody.map(async (item) => {
        // Fetch product data based on product code
        const productResponse = await axios.get(
          `${Config.base_url}Product/GetBy/${CompanyID}?code=${item.productCode}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );

        const product = productResponse.data.listofProducts[0];
        if (!product) return;

        // Calculate new opening quantity based on the default unit
        const openingQuantity =
          (product.openingQuantity || 0) +
          (item.defaultUnit === "Quantity"
            ? item.quantity
            : item.defaultUnit === "Weight"
              ? item.weight
              : item.length || 0);

        // Prepare the updated product data
        const updatedProduct = [{
          ...product,
          openingQuantity,
          isActive: true,
          isDeleted: false,
        }];

        // Send patch request to update the product
        await axios.patch(`${Config.base_url}Product/UpdateRecords`, updatedProduct, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        });
      });

      // Run all update requests in parallel
      await Promise.all(updateProductRequests);

      message.success("Stock In deleted successfully.");
      fetchProducts(); // Fetch products to refresh the UI

    } catch (error) {
      console.error("Error deleting product:", error);
      message.error("An error occurred while deleting stock.");
    }
  };

  const onReset = () => {
    form.resetFields();
    setNominalAccount("");
    setType("");
    setDate("");
    setOpenDate("");
    // fetchAccounts(); // Fetch all data again without any filters
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Nominal Account",
      dataIndex: "nominalAccount",
      key: "nominalAccount",
    },
    {
      title: "Type",
      dataIndex: "adjustType",
      key: "adjustType",
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <NavLink
          className="primary"
          to={
            record.adjustType === "In"
              ? `/products/edit-stock-in/${record.invoiceNo}`
              : `/products/edit-stock-out/${record.invoiceNo}`
          }
        >
          {record.invoiceNo}
        </NavLink>
      )
    },
    {
      title: "Doc No",
      dataIndex: "docNo",
      key: "docNo",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Action",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => (
        <div className="table-actions">
          <NavLink
            className="primary"
            to={
              record.adjustType === "In"
                ? `/products/edit-stock-in/${record.invoiceNo}`
                : `/products/edit-stock-out/${record.invoiceNo}`
            }
          >
            <EditOutlined />
          </NavLink>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this account?"
            onConfirm={record.adjustType === "In"
              ? () => deleteStockIn(record.id)
              : () => deleteStockOut(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const items = [
    {
      label: <NavLink to="/products/stock-out">Stock Out (ADO)</NavLink>,
      key: "1",
      icon: <StockOutlined />,
    },
    {
      label: <NavLink to="/products/stock-in">Stock In (ADI)</NavLink>,
      key: "2",
      icon: <StockOutlined />,
    },
  ];
  const menuProps = {
    items,
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Stock Adjustment");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Date", key: "date", width: 30 },
      { header: "Nominal Account", key: "nominalAccount", width: 30 },
      { header: "Type", key: "adjustType", width: 30 },
      { header: "Invoice No", key: "invoiceNo", width: 20 },
      { header: "Doc No", key: "docNo", width: 30 },
      { header: "Total", key: "total", width: 20 },
    ];

    // Add rows to the sheet
    ListOfProducts.forEach((stock, index) => {
      sheet.addRow({
        sr: index + 1,
        date: stock.date,
        nominalAccount: stock.nominalAccount,
        adjustType: stock.adjustType,
        invoiceNo: stock.invoiceNo,
        docNo: stock.docNo,
        openingQuantity: stock.openingQuantity,
        total: stock.total,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `StockAdjustmentList_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const sortedData = ListOfProducts.sort((a, b) => {
    if (a.code < b.code) return 1;
    if (a.code > b.code) return -1;
    return 0;
  });

  const handleDateChange = (e, value) => {
    setOpenDate(value);
  };
  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Production</h5>
        <ProductionMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header"><SubMenuToggle />
            <h3 className="page-title">Stock Adjustment</h3>
            <div className="header-actions">
              {/* <NavLink to="/stock-adjustment/import">
                <Button type="dashed" icon={<DownloadOutlined />}>
                  Import
                </Button>
              </NavLink> */}
              <Button
                type="dashed"
                onClick={handleExport}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
              <NavLink>
                <Dropdown menu={menuProps}>
                  <Button type="primary">
                    <Space>
                      <PlusOutlined />
                      New
                    </Space>
                  </Button>
                </Dropdown>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form onFinish={handleFilters} form={form} layout="vertical">
              <Form.Item name="adjustType">
                <Select placeholder="Type" style={{ width: 120 }}>
                  <Select.Option value="">All Types</Select.Option>
                  <Select.Option value="In">In</Select.Option>
                  <Select.Option value="Out">Out</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="nominalAccount">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.label
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Nominal Account"
                  style={{ width: "250px" }}
                  notFoundContent={ListOfAccountsLoading ? <Spin /> : null}
                  options={ListOfAccounts.map(
                    (record) => ({
                      label: `${record.accountDescription} (${record.accountCode})`,
                      value: `${record.accountDescription} (${record.accountCode})`,
                    })
                  )}
                />
              </Form.Item>
              <Form.Item name="date">
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handleDateChange}
                  placeholder="Date"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
              <Button type="link" onClick={onReset}>
                Reset
              </Button>
            </Form>
          </div>

          <Table
            columns={columns}
            dataSource={sortedData}
            size="small"
            loading={loading}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}

export default StockAdjustment;
