import React, { useState, useRef, useEffect, useCallback } from "react";
import { debounce, throttle } from "lodash";
import {
    DeleteOutlined,
    DownOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    message,
    Divider,
    Space,
    Collapse,
    Dropdown,
    Spin,
    Table,
    Typography,
    Skeleton,
    Flex,
} from "antd";
import dayjs from "dayjs";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import ProductionMenu from "../ProductionMenu";
import { async } from "q";

const { Option } = Select;

const EditStockIn = () => {
    const navigate = useNavigate();
    const params = useParams();
    const AccessKey = localStorage.getItem("AccessKey");
    const UserName = localStorage.getItem("Full_Name");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");

    const [ProductForm] = Form.useForm();
    const [MainForm] = Form.useForm();
    const [ProductList, setProductList] = useState([]);

    const [loadingProduct, setloadingProduct] = useState(false);
    const [loadingMode, setloadingMode] = useState(false);

    const [loading, setLoading] = useState(false);
    const [CustomerLoading, setCustomerLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [CustomerForm] = Form.useForm();
    const [formMain] = Form.useForm();
    const [OpenDate, setOpenDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [ListOfProducts, setListOfProducts] = useState([]);
    const [ListOfBank, setListOfBank] = useState([]);
    const [selectedProduct, setSelectedCustomer] = useState({});
    const [createdDate, setCreatedDate] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [loadingStockHeadData, setLoadingStockHeadData] = useState(false);
    const [loadingStockBodyData, setLoadingStockBodyData] = useState(false);
    const [product, setProduct] = useState([]);
    const [stockHead, setStockHead] = useState([]);


    const [form] = Form.useForm();

    const handleSubmit = async (FormData) => {
        setLoading(true);

        // Check if at least one product is added
        const fields = form.getFieldValue("users");
        if (fields.length === 0) {
            message.error("Please add at least one product.");
            setLoading(false);
            return;
        }

        // Prepare customer data
        const CustomerData = {
            ...FormData,
            ...stockHead,
            date: OpenDate || dayjs().format("YYYY-MM-DD"),
            total: FormData.total || 0,
            extra1: "",
            extra2: "",
            extra3: "",
            notes: FormData.notes || "",
            docNo: FormData.docNo || "",
            AdjustType: "In",
            AdjustBy: UserName,
            companyID: CompanyID,
            isActive: true,
            isDeleted: false,
        };

        try {
            // Add stock head
            const saleResponse = await axios.patch(
                `${Config.base_url}StockAdjustHead/UpdateRecord/${stockHead.id}`,
                CustomerData,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );

            if (saleResponse.data.status_code === 1) {
                const invoice = saleResponse.data.stockHead.invoiceNo;
                const date = saleResponse.data.stockHead.date;

                // Prepare product data
                const productData = fields.map((item) => ({
                    ...item,
                    date: date,
                    weight: item.weight || 0,
                    length: item.length || 0,
                    extra1: "",
                    extra2: "",
                    extra3: "",
                    AdjustType: "In",
                    AdjustBy: UserName,
                    companyID: CompanyID,
                    isActive: true,
                    isDeleted: false,
                    invoiceNo: invoice,
                }));

                await axios.patch(
                    `${Config.base_url}StockAdjustBody/UpdateRecords`,
                    productData,
                    {
                        headers: {
                            Authorization: `Bearer ${AccessKey}`,
                        },
                    }
                );

                // Update products
                const updateProduct = fields.map((item) => ({
                    ...item,
                    name: FormData.name || "",
                    type: FormData.type || "",
                    date: OpenDate || dayjs().format("YYYY-MM-DD"),
                    cost: FormData.cost || 0,
                    unit: FormData.unit || "",
                    notes: FormData.notes || "",
                    weight: FormData.weight || 0,
                    field1: FormData.field1 || "",
                    field2: FormData.field2 || "",
                    field3: FormData.field3 || "",
                    field4: FormData.field4 || "",
                    fieldA: FormData.fieldA || "",
                    fieldB: FormData.fieldB || "",
                    fieldC: FormData.fieldC || "",
                    fieldD: FormData.fieldD || "",
                    category: FormData.category || "",
                    maxRRExTax: FormData.maxRRExTax || 0,
                    salePrice: FormData.salePrice || 0,
                    openingRate: FormData.openingRate || 0,
                    saleDiscount: FormData.saleDiscount || 0,
                    stockAssetAccount: FormData.stockAssetAccount || "",
                    lowStockLevel: FormData.lowStockLevel || 0,
                    incomeAccount: FormData.incomeAccount || "",
                    expenseAccount: FormData.expenseAccount || "",
                    purchaseDiscount: FormData.purchaseDiscount || 0,
                    gstRate: FormData.gstRate || "",
                    saleInformation: FormData.saleInformation || "",
                    nonFilerGSTRate: FormData.nonFilerGSTRate || "",
                    maxRRIncTax: FormData.maxRRIncTax || 0,
                    binLocation: FormData.binLocation || "",
                    largePackSize: FormData.largePackSize || 0,
                    smallPackSize: FormData.smallPackSize || 0,
                    prefferedSupplier: FormData.prefferedSupplier || "",
                    productType: "Stock",
                    size: FormData.size || "",
                    companyID: CompanyID,
                    defaultUnit: "",
                    id: item.selectedProduct,
                    openingQuantity: item.openingQuantity + (item.defaultUnit === "Quantity" ? item.quantity : item.defaultUnit === "Weight" ? item.weight : item.length) - (item.defaultUnit === "Quantity" ? item.BaseQuantity : item.defaultUnit === "Weight" ? item.BaseWeight : item.BaseLength),
                    isActive: true,
                    isDeleted: false,
                    categoryCode: ""
                }))

                await axios.patch(
                    Config.base_url +
                    `Product/UpdateRecords`,
                    updateProduct,
                    {
                        headers: {
                            Authorization: `Bearer ${AccessKey}`,
                        },
                    }
                );

                message.success(
                    <>
                        Invoice Updated Successfully Against <br />
                        Invoice No: <span style={{ color: "blue" }}>{invoice}</span>
                    </>
                );

                setProductList([]);
                setLoading(false);
                navigate("/products/stock-adjustment");
                CustomerForm.resetFields();
                ProductForm.resetFields();
            } else {
                // Error adding product data
                message.error("Error in Adding Product Data");
                setLoading(false);
            }
        } catch (error) {
            // Error in adding stock head
            console.error(error);
            message.error("Error in Adding Stock Head");
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = "Edit Stock In";
        fetchProducts();
        fetchBankAccounts();
        fetchStockAdjustHead()
        fetchStockAdjustBody()
    }, []);

    const fetchStockAdjustHead = async () => {
        setLoadingStockHeadData(true); // Start loading
        try {
            const { data } = await axios.get(
                `${Config.base_url}StockAdjustHead/GetStockAdjustHeadBy/${params.id}/${CompanyID}`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (data.status_code === 1) {
                // Set form values and date
                CustomerForm.setFieldsValue(data.stockAdjustData);
                if (CompanyID != data.stockAdjustData.companyID) {
                    navigate("/products/stock-adjustment");
                }
                setCreatedDate(data.stockAdjustData.createdDate);
                setCreatedBy(data.stockAdjustData.adjustBy);
                const productDate = CustomerForm.getFieldValue("date");
                setOpenDate(productDate);
                setStockHead(data.stockAdjustData);
            }
        } catch (error) {
            console.error("Error fetching salehead data:", error);
        } finally {
            setLoadingStockHeadData(false); // Stop loading
        }
    }

    const fetchStockAdjustBody = async () => {
        setLoadingStockBodyData(true);
        try {
            const { data } = await axios.get(
                `${Config.base_url}StockAdjustBody/GetStockAdjustBody/${params.id}/${CompanyID}`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (data.status_code === 1) {
                setProductList(data.stockAdjustBody);
                setProduct(data.stockAdjustBody);
                form.setFieldsValue({ users: data.stockAdjustBody });
            }
        } catch (error) {
            console.error("Error fetching salebody data:", error);
        } finally {
            setLoadingStockBodyData(false);
        }
    }

    useEffect(() => {
        if (ListOfProducts.length > 0 && ProductList.length > 0) {
            // Once both ListOfProducts and productList are available, loop through and fetch the selected products
            ProductList.forEach((user, index) => {
                handleFetchProduct(user.productName, index);
                const fields = form.getFieldValue("users");

                fields[index] = {
                    ...fields[index],
                    BaseWeight: user.weight || 0,
                    BaseQuantity: user.quantity || 0,
                    BaseLength: user.length || 0,
                };

                form.setFieldsValue({
                    users: fields,
                });
            });
        }
    }, [ListOfProducts, ProductList]);

    const debouncedHandleFormChange = useCallback(
        debounce(() => {
            const fields = form.getFieldValue("users");
            const amounts = fields.map((item) => parseFloat(item.amount) || 0);
            const discount = fields.map((item) => parseFloat(item.discount) || 0);
            const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
            const net = fields.map((item) => parseFloat(item.net) || 0);

            const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
            const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
            const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
            const total = net.reduce((sum, value) => sum + value, 0);

            setTotalDiscount(totalAmount);
            CustomerForm.setFieldsValue({
                subTotal: totalAmount,
                totalDiscount: totalDiscount,
                totalSaleTax: totalSaleTax,
                total: total,
            });
        }, 1000),
        []
    );

    const handleFetchProduct = (value, index) => {
        //console.log(value);
        const selectedProduct = ListOfProducts.find(
            (item) => item.name === value
        );
        //console.log(selectedProduct);
        if (selectedProduct) {
            const fields = form.getFieldValue("users");
            const quantity = fields[index].quantity || 0;
            const weight = fields[index].quantity || 0;
            const length = fields[index].quantity || 0;
            const rate = selectedProduct.salePrice;
            const amount = (quantity * rate).toFixed(2);
            const discount = (amount * selectedProduct.saleDiscount) / 100;
            const saleTax = (selectedProduct.gstRate * amount) / 100;
            const net = (amount - discount + saleTax).toFixed(2);

            form.setFieldsValue({
                users: fields.map((field, i) =>
                    i === index
                        ? {
                            ...field,
                            defaultUnit: selectedProduct.defaultUnit,
                            openingQuantity: selectedProduct.openingQuantity,
                            selectedProduct: selectedProduct.id,

                        }
                        : field
                ),
            });
            debouncedHandleFormChange();
        } else {
            console.error("Selected product not found in ListOfProducts:", value);
        }
    };

    const fetchProducts = async () => {
        setProductLoading(true);
        const api_config = {
            method: "get",
            url: `${Config.base_url}Product/GetBy/${CompanyID}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${AccessKey}`,
            },
        };

        try {
            const response = await axios(api_config);
            if (response.data && response.data.status_code === 1) {
                setProductLoading(false);
                setListOfProducts(response.data.listofProducts || []);
            } else {
                setProductLoading(false);

                setListOfProducts([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setProductLoading(false);

            setListOfProducts([]);
        }
    };

    const fetchBankAccounts = async () => {
        try {
            const response = await axios.get(
                Config.base_url + `AccountMain/GetBy/${CompanyID}`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (response.data.status_code === 1) {
                setListOfBank(response.data.listofAccounts);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const CustomerDetail = () => {
        const handleDateChange = (e, value) => {
            setOpenDate(value);
        };
        return (
            <>
                <Form layout="vertical" form={CustomerForm} onFinish={handleSubmit}>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="nominalAccount"
                                initialValue="Stock (50101)"
                                label="COA"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Account",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    placeholder="Select.."
                                    style={{ width: "350px" }}
                                    options={ListOfBank.map((fieldThreeitem) => ({
                                        label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                        value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Date" label="Date" required>
                                        <DatePicker
                                            defaultValue={
                                                OpenDate === null
                                                    ? dayjs()
                                                    : dayjs(OpenDate, "YYYY-MM-DD")
                                            }
                                            style={{ width: "100%" }}
                                            onChange={handleDateChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Form.Item name="accountCode" label="Term Days" hidden>
                                    <Input onFocus={(e) => e.target.select()} />
                                </Form.Item>

                                <Col xs={24} md={12}>
                                    <Form.Item name="docNo" label="Doc No.">
                                        <Input onFocus={(e) => e.target.select()} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    };

    const ProductDetail = () => {
        const handleSelectChange = (value, index) => {
            const selectedProduct = ListOfProducts.find(
                (item) => item.name === value
            );
            //console.log(selectedProduct);
            if (selectedProduct) {
                const fields = form.getFieldValue("users");
                const quantity = fields[index].quantity || 0;
                const weight = fields[index].quantity || 0;
                const length = fields[index].quantity || 0;
                const rate = selectedProduct.salePrice;
                const amount = (quantity * rate).toFixed(2);
                const discount = (amount * selectedProduct.saleDiscount) / 100;
                const saleTax = (selectedProduct.gstRate * amount) / 100;
                const net = (amount - discount + saleTax).toFixed(2);

                form.setFieldsValue({
                    users: fields.map((field, i) =>
                        i === index
                            ? {
                                ...field,
                                description: `${selectedProduct.name} ${selectedProduct.saleInformation}`,
                                unit: selectedProduct.unit,
                                weight,
                                length,
                                quantity,
                                rate,
                                amount,
                                discPercentege: selectedProduct.saleDiscount,
                                discount,
                                taxRate: selectedProduct.gstRate,
                                saleTax,
                                net,
                                defaultUnit: selectedProduct.defaultUnit,
                                openingQuantity: selectedProduct.openingQuantity,
                                selectedProduct: selectedProduct.id,
                                productCode: selectedProduct.code,
                            }
                            : field
                    ),
                });
                debouncedHandleFormChange();
            } else {
                console.error("Selected product not found in ListOfProducts:", value);
            }
        };

        const handleQuantityChange = (e, index) => {
            const quantity = parseFloat(e.target.value);
            const fields = form.getFieldValue("users");
            const formInstance = fields[index];

            if (isNaN(quantity) || quantity <= 0) {
                //console.log("Invalid quantity value");
                return;
            }

            if (formInstance.defaultUnit === "Quantity") {
                const amount = (quantity * formInstance.rate).toFixed(2) || 0;
                const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
                const Tax = (formInstance.taxRate * amount) / 100 || 0;
                const net = (amount - discountAmt + Tax).toFixed(2);

                fields[index] = {
                    ...fields[index],
                    quantity,
                    amount,
                    discount: discountAmt,
                    saleTax: Tax,
                    net,
                };

                form.setFieldsValue({
                    users: fields,
                });

                debouncedHandleFormChange();
            } else {
                //console.log("Rate unit is not Quantity");
            }
        };

        const handleWeightChange = (e, index) => {
            const weight = parseFloat(e.target.value);
            const fields = form.getFieldValue("users");
            const formInstance = fields[index];

            if (isNaN(weight) || weight <= 0) {
                //console.log("Invalid weight value");
                return;
            }

            if (formInstance.defaultUnit === "Weight") {
                const amount = (weight * formInstance.rate).toFixed(2) || 0;
                const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
                const Tax = (formInstance.taxRate * amount) / 100 || 0;
                const net = (amount - discountAmt + Tax).toFixed(2);

                fields[index] = {
                    ...fields[index],
                    weight,
                    amount,
                    discount: discountAmt,
                    saleTax: Tax,
                    net,
                };

                form.setFieldsValue({
                    users: fields,
                });

                debouncedHandleFormChange();
            } else {
                //console.log("Rate unit is not Weight");
            }
        };

        const handleLengthChange = (e, index) => {
            const length = parseFloat(e.target.value);
            const fields = form.getFieldValue("users");
            const formInstance = fields[index];

            if (isNaN(length) || length <= 0) {
                //console.log("Invalid length value");
                return;
            }

            if (formInstance.defaultUnit === "Length") {
                const amount = (length * formInstance.rate).toFixed(2) || 0;
                const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
                const Tax = (formInstance.taxRate * amount) / 100 || 0;
                const net = (amount - discountAmt + Tax).toFixed(2);

                fields[index] = {
                    ...fields[index],
                    length,
                    amount,
                    discount: discountAmt,
                    saleTax: Tax,
                    net,
                };

                form.setFieldsValue({
                    users: fields,
                });

                debouncedHandleFormChange();
            } else {
                //console.log("Rate unit is not Length");
            }
        };

        const handleRateChange = (e, index) => {
            const fields = form.getFieldValue("users");

            const formInstance = fields[index];
            const rate = parseFloat(e.target.value) || 0;
            const quantity = parseFloat(formInstance.quantity) || 1;
            const length = parseFloat(formInstance.length) || 1;
            const weight = parseFloat(formInstance.weight) || 1;
            const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

            if (formInstance.defaultUnit === "Length") {
                const amount = length * rate;
                const discountAmt = (discountPercentage * amount) / 100 || 0;
                const Tax = (formInstance.taxRate * amount) / 100 || 0;
                const net = (amount - discountAmt + Tax).toFixed(2);

                fields[index] = {
                    ...fields[index],
                    amount,
                    discount: discountAmt,
                    saleTax: Tax,
                    net,
                };
                form.setFieldsValue({
                    users: fields,
                });
                debouncedHandleFormChange();
            } else if (formInstance.defaultUnit === "Weight") {
                const amount = weight * rate;
                const discountAmt = (discountPercentage * amount) / 100 || 0;
                const Tax = (formInstance.taxRate * amount) / 100 || 0;
                const net = (amount - discountAmt + Tax).toFixed(2);

                fields[index] = {
                    ...fields[index],
                    amount,
                    discount: discountAmt,
                    saleTax: Tax,
                    net,
                };
                form.setFieldsValue({
                    users: fields,
                });
                debouncedHandleFormChange();
            } else if (formInstance.defaultUnit === "Quantity") {
                const amount = quantity * rate;
                const discountAmt = (discountPercentage * amount) / 100 || 0;
                const Tax = (formInstance.taxRate * amount) / 100 || 0;
                const net = (amount - discountAmt + Tax).toFixed(2);

                fields[index] = {
                    ...fields[index],
                    amount,
                    discount: discountAmt,
                    saleTax: Tax,
                    net,
                };
                form.setFieldsValue({
                    users: fields,
                });
                debouncedHandleFormChange();
            } else {
                console.error("Invalid product selection or quantity");
            }
        };

        const handleUnitChange = (value, index) => {
            const fields = form.getFieldValue("users");
            const formInstance = fields[index];
            const rate = parseFloat(formInstance.rate) || 0;
            const quantity = parseFloat(formInstance.quantity) || 0;
            const length = parseFloat(formInstance.length) || 0;
            const weight = parseFloat(formInstance.weight) || 0;
            const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

            let amount = 0;
            let discountAmt = 0;
            let tax = 0;
            let net = 0;

            if (value === "Length") {
                amount = length * rate;
            } else if (value === "Weight") {
                amount = weight * rate;
            } else if (value === "Quantity") {
                amount = quantity * rate;
            }

            discountAmt = (discountPercentage * amount) / 100 || 0;
            tax = (formInstance.taxRate * amount) / 100 || 0;
            net = (amount - discountAmt + tax).toFixed(2);

            fields[index] = {
                ...fields[index],
                amount,
                discount: discountAmt,
                saleTax: tax,
                net,
            };

            form.setFieldsValue({
                users: fields,
            });

            debouncedHandleFormChange(); // Trigger the form change handler after setting values
        };

        const columns = (remove) => [
            {
                title: "Product / Services",
                dataIndex: "productName",
                key: "productName",
                width: 150,
            },

            { title: "Unit", dataIndex: "unit", key: "unit", width: 150 },
            { title: "Quantity", dataIndex: "quantity", key: "quantity", width: 100 },
            { title: "Weight", dataIndex: "weight", key: "weight", width: 100 },
            { title: "Length", dataIndex: "length", key: "length", width: 100 },
            { title: "Rate", dataIndex: "rate", key: "rate", width: 230 },
            {
                title: "Rate Unit",
                dataIndex: "defaultUnit",
                key: "defaultUnit",
                width: 180,
            },
            { title: "Amount", dataIndex: "amount", key: "amount", width: 250 },

            {
                title: "Action",
                dataIndex: "action",
                key: "action",
                width: 110,
                render: (_, { key }) => (
                    <ul className="inline-action">
                        <li>
                            <Link to={`#/`} onClick={() => remove(key)} className="red">
                                <DeleteOutlined />
                            </Link>
                        </li>
                    </ul>
                ),
            },
        ];
        const debouncedHandleFormChange = useCallback(
            debounce(() => {
                const fields = form.getFieldValue("users");
                const amounts = fields.map((item) => parseFloat(item.amount) || 0);
                const discount = fields.map((item) => parseFloat(item.discount) || 0);
                const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
                const net = fields.map((item) => parseFloat(item.net) || 0);

                const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
                const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
                const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
                const total = net.reduce((sum, value) => sum + value, 0);

                setTotalDiscount(totalAmount);
                CustomerForm.setFieldsValue({
                    subTotal: totalAmount,
                    totalDiscount: totalDiscount,
                    totalSaleTax: totalSaleTax,
                    total: total,
                });
            }, 1000),
            []
        );

        return (
            <>
                <Form
                    form={form}
                    name="dynamic_form_nest_item"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    initialValues={{
                        users: [{}],
                    }}
                >
                    <Form.List name="users">
                        {(fields, { add, remove }) => (
                            <>
                                <Table
                                    dataSource={fields.map(
                                        ({ key, name, fieldKey, ...restField }, index) => ({
                                            key,
                                            productName: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "productName"]}
                                                    fieldKey={[fieldKey, "productName"]}
                                                    style={{ width: "250px" }}
                                                >
                                                    <Select
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.label
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }
                                                        onChange={(value) => {
                                                            handleSelectChange(value, index);
                                                        }}
                                                        placeholder="Product / Services"
                                                        variant="borderless"
                                                        loading={loadingMode}
                                                        options={ListOfProducts.map((record) => ({
                                                            label: (
                                                                <>
                                                                    {record.name} - Q : {record.openingQuantity}
                                                                </>
                                                            ),
                                                            value: record.name,
                                                        }))}
                                                    />
                                                </Form.Item>
                                            ),

                                            unit: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "unit"]}
                                                    fieldKey={[fieldKey, "unit"]}
                                                >
                                                    <Input
                                                        placeholder="Unit"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                </Form.Item>
                                            ),
                                            quantity: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "quantity"]}
                                                    fieldKey={[fieldKey, "quantity"]}
                                                >
                                                    <Input
                                                        placeholder="Quantity"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                        onChange={(e) => handleQuantityChange(e, index)}
                                                    />
                                                </Form.Item>
                                            ),
                                            weight: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "weight"]}
                                                    fieldKey={[fieldKey, "weight"]}
                                                >
                                                    <Input
                                                        placeholder="Weight"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                        onChange={(e) => handleWeightChange(e, index)}
                                                    />
                                                </Form.Item>
                                            ),
                                            length: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "length"]}
                                                    fieldKey={[fieldKey, "length"]}
                                                >
                                                    <Input
                                                        placeholder="Length"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                        onChange={(e) => handleLengthChange(e, index)}
                                                    />
                                                </Form.Item>
                                            ),
                                            rate: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "rate"]}
                                                    fieldKey={[fieldKey, "rate"]}
                                                >
                                                    <Input
                                                        placeholder="Rate"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                        onChange={(e) => handleRateChange(e, index)}
                                                    />
                                                </Form.Item>
                                            ),
                                            defaultUnit: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "defaultUnit"]}
                                                    fieldKey={[fieldKey, "defaultUnit"]}
                                                >
                                                    <Select
                                                        variant="borderless"
                                                        placeholder="Rate Unit"
                                                        onChange={(value) => {
                                                            handleUnitChange(value, index);
                                                        }}
                                                    >
                                                        <Option value="Quantity">Quantity</Option>
                                                        <Option value="Weight">Weight</Option>
                                                        <Option value="Length">Length</Option>
                                                    </Select>
                                                </Form.Item>
                                            ),
                                            amount: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "amount"]}
                                                    fieldKey={[fieldKey, "amount"]}
                                                >
                                                    <Input
                                                        placeholder="Amount"
                                                        readOnly
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                </Form.Item>
                                            ),

                                            openingQuantity: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "openingQuantity"]}
                                                    fieldKey={[fieldKey, "openingQuantity"]}
                                                    hidden
                                                >
                                                    <Input
                                                        placeholder="Unit"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                </Form.Item>
                                            ),
                                            selectedProduct: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "selectedProduct"]}
                                                    fieldKey={[fieldKey, "selectedProduct"]}
                                                    hidden
                                                >
                                                    <Input
                                                        placeholder="Unit"
                                                        variant="borderless"
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                </Form.Item>
                                            ),
                                            BaseLength: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "BaseLength"]}
                                                    fieldKey={[fieldKey, "BaseLength"]}
                                                    hidden
                                                >
                                                    <Input placeholder="Unit" variant="borderless" onFocus={(e) => e.target.select()} />
                                                </Form.Item>
                                            ),
                                            BaseQuantity: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "BaseQuantity"]}
                                                    fieldKey={[fieldKey, "BaseQuantity"]}
                                                    hidden
                                                >
                                                    <Input placeholder="Unit" variant="borderless" onFocus={(e) => e.target.select()} />
                                                </Form.Item>
                                            ),
                                            BaseWeight: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "BaseWeight"]}
                                                    fieldKey={[fieldKey, "BaseWeight"]}
                                                    hidden
                                                >
                                                    <Input placeholder="Unit" variant="borderless" onFocus={(e) => e.target.select()} />
                                                </Form.Item>
                                            ),
                                            id: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "id"]}
                                                    fieldKey={[fieldKey, "id"]}
                                                    hidden
                                                >
                                                    <Input onFocus={(e) => e.target.select()} />
                                                </Form.Item>
                                            ),
                                            productCode: (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, "productCode"]}
                                                    fieldKey={[fieldKey, "productCode"]}
                                                    hidden
                                                >
                                                    <Input onFocus={(e) => e.target.select()} />
                                                </Form.Item>
                                            ),
                                            action: (
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            ),
                                        })
                                    )}
                                    columns={columns(remove)}
                                    pagination={false}
                                    size="small"
                                />

                                <Form.Item>
                                    <Button
                                        style={{ marginTop: 10 }}
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                    >
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </>
        );
    };

    const formatDate = (isoDateString) => {
        const [datePart] = isoDateString.split("T");
        const [year, month, day] = datePart.split("-");
        return `${day}/${month}/${year}`;
    };


    return (
        <>
            <div id="sub-menu-wrap">
                <h5>Production</h5>
                <ProductionMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content mb-5">
                    <div className="page-header"><SubMenuToggle />
                        <h3 className="page-title">
                            <NavLink to="/products/stock-adjustment">
                                <ArrowLeftIcon />
                            </NavLink>
                            Edit Stock In
                        </h3>
                    </div>

                    {loadingStockHeadData ?
                        <>
                            <Skeleton active />
                            <Skeleton active />
                            <Skeleton active />
                        </>
                        :
                        <>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Collapse
                                    collapsible="header"
                                    defaultActiveKey={["Customer Details"]}
                                    items={[
                                        {
                                            key: "Customer Details",
                                            label: "Details",
                                            children: <CustomerDetail />,
                                        },
                                    ]}
                                />
                                <Collapse
                                    collapsible="header"
                                    defaultActiveKey={["Product Details"]}
                                    items={[
                                        {
                                            key: "Product Details",
                                            label: "Product Details",
                                            children: <ProductDetail />,
                                        },
                                    ]}
                                />
                                <Form layout="vertical" form={CustomerForm} onFinish={handleSubmit}>
                                    <Row gutter={[24, 0]}>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="notes" label="Notes">
                                                <Input.TextArea rows={5} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <h4>
                                                <Form.Item
                                                    name="total"
                                                    label="Total"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter valid data",
                                                        },
                                                    ]}
                                                >
                                                    <Input onFocus={(e) => e.target.select()} readOnly />
                                                </Form.Item>
                                            </h4>
                                        </Col>
                                    </Row>
                                </Form>
                            </Space>
                            <Form form={CustomerForm} onFinish={handleSubmit}>
                                <Form.Item>
                                    <Flex justify="space-between" align="center">
                                        <Typography.Text>
                                            <h5>
                                                Created By : {createdBy} - {formatDate(createdDate)}
                                            </h5>
                                        </Typography.Text>

                                        <Button type="primary" htmlType="submit" loading={loading}>
                                            Edit
                                        </Button>
                                    </Flex>
                                </Form.Item>
                            </Form>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default EditStockIn;