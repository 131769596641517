import {
  Form,
  Input,
  Select,
  Table,
  Button,
  Dropdown,
  Space,
  Menu,
  Popconfirm,
  message,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import axios from "axios";
import AddSupplierModal from "../Common/AddSupplierModal";
import SupplierMenu from "./SupplierMenu";
import PurchaseMenu from "../Purchases/PurchaseMenu";
const ExcelJS = require("exceljs");

function ManageSupplier() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [loading, setLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [OrderBy, setOrderBy] = useState("");
  const [Name, setName] = useState("");
  const [AccountCode, setAccountCode] = useState("");
  const [Level, setLevel] = useState("0");
  const [SupplierID, setSupplierID] = useState("");

  const [IsDeleted, setIsDeleted] = useState(false);
  const [SupplierLoading, setSupplierLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);

  const [form] = Form.useForm();
  const [formMain] = Form.useForm();

  const fetchSupplier = async () => {
    setLoading(true);

    const api_config = {
      method: "get",
      url: `${Config.base_url}CustomerSupplier/GetSuppliersBy/${CompanyID}?orderBy=${OrderBy}&businessName=${Name}&accountCode=${AccountCode}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    //console.log("Fetching data from URL:", api_config.url);

    try {
      const response = await axios(api_config);
      //console.log("API response:", response.data);

      if (response.data && response.data.status_code === 1) {
        //console.log("Filtered Customers:", response.data.listofSuppliers);
        //console.log("Total Records:", response.data.totalRecords);

        setListOfRecords(response.data.listofSuppliers || []);
        setTotalRecords(response.data.totalRecords || 0);
      } else {
        console.warn(
          "No data or error status_code:",
          response.data.status_code
        );
        setListOfRecords([]);
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response?.data || error.message
      );
      setListOfRecords([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = "Manage Suppliers";

    fetchSupplier();
  }, [OrderBy, AccountCode, Name]);

  const handleFilters = (formData) => {
    //console.log("Form Submitted with:", formData);

    setName(formData["businessName"] || "");
    setOrderBy(formData["OrderBy"] || "");
    setAccountCode(formData["accountCode"] || "");
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Supplier Name",
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: "Account No.",
      dataIndex: "",
      key: "accountNo",
      render: (text, record) => (
        <>
          {record.isSupplier && parseInt(record.accountCode) < 9000 ? (
            <>{record.accountNo} (S)</>
          ) : (
            <>
              {record.isCustomer && parseInt(record.accountCode) > 9000 ? (
                <>{record.accountNo} (C)</>
              ) : (
                <>{record.accountNo}</>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: "Contact",
      dataIndex: "",
      key: "phone",
      render: (text, record) => (
        <>
          {record.title ? (
            <>
              {record.title}. {record.firstName} {record.lastName}
            </>
          ) : (
            <>
              {record.firstName} {record.lastName}
            </>
          )}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Balance",
      dataIndex: "supplierOpeningBalance",
      key: "supplierOpeningBalance",
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => (
        <div className="table-actions">
          <NavLink
            className={"primary"}
            htmlType="button"
            onClick={() => handleSupplierEdit(record.id)}
          >
            <EditOutlined />
          </NavLink>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this Supplier?"
            onConfirm={(e) => deleteAccount(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const deleteAccount = async (ID) => {
    setLoading(true);

    try {
      setLoading(true);

      const {
        data: { customerData },
      } = await axios.get(
        `${Config.base_url}CustomerSupplier/GetCustomerBy/${ID}`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      const updateData = {
        ...customerData,
        id: ID,
        companyID: CompanyID,
        isSupplier: false,
      };

      const response = await axios.patch(
        `${Config.base_url}CustomerSupplier/DeleteRecord/${ID}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Supplier Deleted Successfully");
        fetchSupplier();
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSupplierEdit = (ID) => {
    setSupplierLoading(true);
    setSupplierID(ID);
    setOpen(true);
  };
  const onReset = () => {
    form.resetFields();
    setName("");
    setAccountCode("");
    setOrderBy("");
  };
  const handleOk = (FormData) => {
    setLoading(true);
    setOpen(false);
    setLoading(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Suppliers");

    // Set column headers and their widths
    sheet.columns = [
      { header: "SupplierName", key: "businessName", width: 30 },
      { header: "Title", key: "title", width: 10 },
      { header: "FirstName", key: "firstName", width: 30 },
      { header: "LastName", key: "lastName", width: 30 },
      { header: "Email", key: "email", width: 30 },
      { header: "Mobile", key: "mobile", width: 30 },
      { header: "Balance", key: "supplierOpeningBalance", width: 10 },
    ];

    // Add rows to the sheet
    ListOfRecords.forEach((supplier, index) => {
      sheet.addRow({
        sr: index + 1,
        businessName: supplier.businessName,
        title: supplier.title,
        firstName: supplier.firstName,
        lastName: supplier.lastName,
        email: supplier.email,
        mobile: supplier.mobile,
        supplierOpeningBalance: supplier.supplierOpeningBalance,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `SuppliersList_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      {/* show={show} handleClose={handleClose} */}
      <AddSupplierModal
        show={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={SupplierLoading}
        SupplierID={SupplierID}
      />
      <div id="sub-menu-wrap">
        <h5>Purchases</h5>
        <PurchaseMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">Manage Supplier</h3>
            <div className="header-actions">
              <NavLink to="/supplier/import">
                <Button type="dashed" icon={<DownloadOutlined />}>
                  Import
                </Button>
              </NavLink>
              <Button
                type="dashed"
                onClick={handleExport}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSupplierID(null);
                  setOpen(true);
                }}
              >
                New
              </Button>
            </div>
          </div>
          <div className="filters-wrap">
            <Form onFinish={handleFilters} form={form}>
              <Form.Item name="OrderBy">
                <Select placeholder="Order By" style={{ width: 120 }}>
                  <Select.Option value="accountcode">Account No</Select.Option>
                  <Select.Option value="businessname">
                    Supplier Name
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="businessName">
                <Input placeholder="Supplier Name" />
              </Form.Item>
              <Form.Item name="accountCode">
                <Input placeholder="Account No" />
              </Form.Item>

              <Button htmlType="submit" type="primary">
                Filter
              </Button>
              <Button htmlType="button" onClick={onReset} type="link">
                Reset
              </Button>
            </Form>
          </div>

          <Table
            columns={columns}
            dataSource={ListOfRecords}
            size="small"
            loading={loading}
            pagination={{ defaultPageSize: 20 }}
          />
        </div>
      </div>
    </>
  );
}

export default ManageSupplier;
