import {
    Form,
    Input,
    Select,
    Table,
    Button,
    Popconfirm,
    message,
    Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import axios from "axios";
import SettingMenu from "./SettingMenu";

function ResetCompany(props) {
    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");

    const [loading, setLoading] = useState(false);
    const [ListOfUsers, setListOfUsers] = useState([]);
    const [role, setRole] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [roles, setRoles] = useState([]);
    const [roleLoading, setRoleLoading] = useState(false);

    const [form] = Form.useForm();

    const storedRoles = JSON.parse(localStorage.getItem("roles"));

    useEffect(() => {
        document.title = "Reset Company";
    }, []);


    const deleteAccount = async (Type) => {
        if (storedRoles.includes("0d42ee65-ea41-421e-81a3-af81fa61dcb8")) {
            try {
                if (Type === "Reset Products") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-products?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );

                        message.success("Product Reset successfully.");
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                }
                else if (Type === "Reset Sales") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-sale-head?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {
                        await axios.delete(
                            `${Config.base_url}Reset/delete-sale-body?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-receipt-head?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-receipt-body?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    message.success("Sale Reset successfully.");
                }
                else if (Type === "Reset Purchases") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-purchase-head?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {
                        await axios.delete(
                            `${Config.base_url}Reset/delete-purchase-body?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-payment-head?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-payment-body?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    message.success("Purchase Reset successfully.");
                }
                else if (Type === "Reset Receipts") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-receipt-head?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-receipt-body?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    message.success("Receipt Reset successfully.");
                }
                else if (Type === "Reset Payments") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-payment-head?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-payment-body?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    message.success("Payment Reset successfully.");
                }
                else if (Type === "Reset Customers") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-customers?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    message.success("Customer Reset successfully.");
                }
                else if (Type === "Reset Suppliers") {
                    try {

                        await axios.delete(
                            `${Config.base_url}Reset/delete-suppliers?companyId=${CompanyID}`,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${AccessKey}`,
                                },
                            }
                        );
                    }
                    catch (error) {
                        console.error("Error deleting user:", error);
                    }
                    message.success("Customer Reset successfully.");
                }
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
        else {
            message.error("You don't have access to perform this task.");
        }

    };

    const dataSources = [
        {
            id: "1",
            name: "Reset Customers",
        },
        {
            id: "2",
            name: "Reset Suppliers",
        },
        {
            id: "3",
            name: "Reset Products",
        },
        {
            id: "4",
            name: "Reset Sales",
        },
        {
            id: "5",
            name: "Reset Purchases",
        },
        {
            id: "6",
            name: "Reset Receipts",
        },
        {
            id: "7",
            name: "Reset Payments",
        },
    ]

    const columns = [
        {
            title: "Sr#",
            dataIndex: "",
            key: "SR",
            render: (_, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) =>
                <div className="table-actions">
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this account?"
                        onConfirm={() =>
                            deleteAccount(
                                record.name
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                </div>
        }


    ];

    return (
        <>
            <div id="sub-menu-wrap">
                <h5>Settings</h5>
                <SettingMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header"><SubMenuToggle />
                        <h3 className="page-title">Reset Company</h3>
                    </div>

                    <Table
                        columns={columns}
                        size="small"
                        loading={loading}
                        dataSource={dataSources}
                    />
                </div>
            </div>
        </>
    );
}

export default ResetCompany
