import React, { useState, useEffect } from "react";
import ProductionMenu from "./ProductionMenu";
import {
  PlusOutlined,
  PlusCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import { ArrowLeftIcon } from "@heroicons/react/24/solid";

import { NavLink, Link, useNavigate, useParams } from "react-router-dom";

import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Radio,
  Select,
  Button,
  Upload,
  message,
  Collapse,
  Flex,
  Typography,
  DatePicker,
  Spin,
  Modal,
  Table,
  Skeleton,
  Space,
  Divider,
} from "antd";
import axios from "axios";

import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import dayjs from "dayjs";

function EditStockProducts(props) {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const FYear = localStorage.getItem("DefaultFYear");
  const CompanyID = localStorage.getItem("CompanyID");
  const params = useParams();
  // Drop Down Arrays
  const [Category, setCategory] = useState([]);
  const [Type, setType] = useState([]);
  const [Size, setSize] = useState([]);
  const [Unit, setUnit] = useState([]);
  const [stockAsset, setStockAsset] = useState([]);
  const [stockAsset1, setStockAsset1] = useState([]);
  const [stockAsset2, setStockAsset2] = useState([]);
  const [incomeAsset, setIncomeAsset] = useState([]);
  const [incomeAsset1, setIncomeAsset1] = useState([]);
  const [incomeAsset2, setIncomeAsset2] = useState([]);
  const [expenseAsset, setExpenseAsset] = useState([]);
  const [expenseAsset1, setExpenseAsset1] = useState([]);
  const [expenseAsset2, setExpenseAsset2] = useState([]);
  const [product, setProduct] = useState({});
  // // loadings
  const [loading, setLoading] = useState(false);
  const [loadingSaveAccount, setLoadingSaveAccount] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [typeLoading, setTypeLoading] = useState(false);
  const [sizeLoading, setSizeLoading] = useState(false);
  const [unitLoading, setUnitLoading] = useState(false);
  const [stockAssetLoading, setStockAssetLoading] = useState(false);
  const [incomeAssetLoading, setIncomeAssetLoading] = useState(false);
  const [expenseAssetLoading, setExpenseAssetLoading] = useState(false);

  // Modal Opening
  const [isCategoeyModalOpen, setIsCategoryModal] = useState(false);
  const [isTypeModalOpen, setIsTypeModal] = useState(false);
  const [isSizeModalOpen, setIsSizeModal] = useState(false);
  const [isUnitModalOpen, setIsUnitModal] = useState(false);
  const [isStockAccountModalOpen, setIsStockAccountModalOpen] = useState(false);
  const [isIncomeAccountModalOpen, setIsIncomeAccountModalOpen] =
    useState(false);
  const [isExpenseAccountModalOpen, setIsExpenseAccountModalOpen] =
    useState(false);
  const [CategoryCode, setCategoryCode] = useState(null);

  //Image Upload
  const [file, setFile] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [OpenDate, setOpenDate] = useState(null);

  const [form] = Form.useForm();
  const [AccountForm] = Form.useForm();

  const handleSubmit = async (FormData) => {
    setLoading(true);

    const data = {
      ...product,
      name: FormData.name || "",
      type: FormData.type || "",
      date: OpenDate,
      cost: FormData.cost || 0,
      unit: FormData.unit || "",
      notes: FormData.notes || "",
      weight: FormData.weight || 0,
      field1: FormData.field1 || "",
      field2: FormData.field2 || "",
      field3: FormData.field3 || "",
      field4: FormData.field4 || "",
      fieldA: FormData.fieldA || "",
      fieldB: FormData.fieldB || "",
      fieldC: FormData.fieldC || "",
      fieldD: FormData.fieldD || "",
      category: FormData.category || "",
      maxRRExTax: FormData.maxRRExTax || 0,
      salePrice: FormData.salePrice || 0,
      saleDiscount: FormData.saleDiscount || 0,
      stockAssetAccount: FormData.stockAssetAccount || "",
      lowStockLevel: FormData.lowStockLevel || 0,
      incomeAccount: FormData.incomeAccount || "",
      expenseAccount: FormData.expenseAccount || "",
      purchaseDiscount: FormData.purchaseDiscount || 0,
      gstRate: FormData.gstRate || "",
      saleInformation: FormData.saleInformation || "",
      nonFilerGSTRate: FormData.nonFilerGSTRate || "",
      maxRRIncTax: FormData.maxRRIncTax || 0,
      binLocation: FormData.binLocation || "",
      largePackSize: FormData.largePackSize || 0,
      smallPackSize: FormData.smallPackSize || 0,
      prefferedSupplier: FormData.prefferedSupplier || "",
      productType: "Stock",
      size: FormData.size || "",
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
      categoryCode: CategoryCode || product.categoryCode,
    };

    //console.log(data);
    try {
      const response = await axios.patch(
        Config.base_url + `Product/UpdateRecord/${params.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Product Updated Successfully");
        setLoading(false);
        if (FormData.Image) {
          await updateProfile_Img(data.name);
        } else {
          navigate("/products/manage");
        }
        form.resetFields();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

  const onFileChange = (info) => {
    const { file } = info;

    if (file.status === "done" || file.status === "uploading") {
      if (file.size > MAX_IMAGE_SIZE) {
        message.error("Image size must be less than 2 MB");
        return;
      }

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        message.error("Only JPG, PNG files are allowed.");
        return;
      }

      setFile(file);
      setImageSelected(true);
      //console.log("File selected:", file);
    }
  };

  const updateProfile_Img = async (type) => {
    setLoading(true);
    if (!file) {
      message.error("No file selected");
      setLoading(false);
      return;
    }
    //console.log(file);
    const formData = new FormData();
    formData.append("file", file.originFileObj);

    //console.log(formData);
    try {
      setUploading(true);
      const response = await axios.post(
        `${Config.base_url}Logo/AddLogo/${CompanyID}?type=${type}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Image has been saved successfully.");
      setLoading(false);
      setFile(null);
      setImageSelected(false);
      navigate("/products/manage");
    } catch (error) {
      message.error("Error uploading file");
      console.error("Upload error:", error); // Log the error
      setLoading(false);
    } finally {
      setUploading(false);
    }
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "accountDescription",
      key: "accountDescription",
    },
    {
      title: "Account Code",
      dataIndex: "accountCode",
      key: "accountCode",
    },
  ];

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const GetCategoryDropdownData = async () => {
    setCategoryLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `DropdownData/GetDropdownData/${CompanyID}?Type=ProductCategory`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setCategory(response.data.dropdownData);
        setCategoryLoading(false);
      }
    } catch (error) {
      console.error(error);
      setCategoryLoading(false);
    }
  };

  const GetTypeDropdownData = async () => {
    setTypeLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `DropdownData/GetDropdownData/${CompanyID}?Type=ProductType`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setType(response.data.dropdownData);
        setTypeLoading(false);
      }
    } catch (error) {
      console.error(error);
      setTypeLoading(false);
    }
  };
  const GetSizeDropdownData = async () => {
    setSizeLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `DropdownData/GetDropdownData/${CompanyID}?Type=ProductSize`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setSize(response.data.dropdownData);
        setSizeLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSizeLoading(false);
    }
  };
  const GetUnitDropdownData = async () => {
    setUnitLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `DropdownData/GetDropdownData/${CompanyID}?Type=ProductUnit`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setUnit(response.data.dropdownData);
        setUnitLoading(false);
      }
    } catch (error) {
      console.error(error);
      setUnitLoading(false);
    }
  };
  const GetStockAccountDropdownData = async () => {
    setStockAssetLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=50101&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setStockAsset(response.data.listofAccounts);
        setStockAssetLoading(false);
      }
    } catch (error) {
      console.error(error);
      setStockAssetLoading(false);
    }
  };
  const GetIncomeAccountDropdownData = async () => {
    setIncomeAssetLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=60103&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setIncomeAsset(response.data.listofAccounts);
        setIncomeAssetLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIncomeAssetLoading(false);
    }
  };
  const GetExpenseAccountDropdownData = async () => {
    setExpenseAssetLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=70101&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setExpenseAsset(response.data.listofAccounts);
        setExpenseAssetLoading(false);
      }
    } catch (error) {
      console.error(error);
      setExpenseAssetLoading(false);
    }
  };

  const GetProductData = async () => {
    setLoadingSaveAccount(true); // Start loading

    try {
      const { data } = await axios.get(
        `${Config.base_url}Product/GetProductBy/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (data.status_code === 1) {
        // Set form values and date
        form.setFieldsValue(data.productData);
        // console.log(data.productData);
        //console.log(CompanyID);
        if (CompanyID != data.productData.companyID) {
          navigate("/products/manage");
        }
        const productDate = form.getFieldValue("date"); // Ensure 'date' is the correct field key
        setOpenDate(productDate);
        setProduct(data.productData);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoadingSaveAccount(false); // Stop loading
    }
  };

  useEffect(() => {
    document.title = "Edit Stock Products";
    GetCategoryDropdownData();
    GetTypeDropdownData();
    GetSizeDropdownData();
    GetUnitDropdownData();
    GetStockAccountDropdownData();
    GetIncomeAccountDropdownData();
    GetExpenseAccountDropdownData();
    GetProductData();
  }, [CompanyID]);

  // Modal Opening Funcitons
  const showCategoryModal = () => {
    setIsCategoryModal(true);
  };
  const showTypeModal = () => {
    setIsTypeModal(true);
  };
  const showSizeModal = () => {
    setIsSizeModal(true);
  };
  const showUnitModal = () => {
    setIsUnitModal(true);
  };
  const showStockAccountModal = async () => {
    setIsStockAccountModalOpen(true);
    try {
      const response1 = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=50&accountName=CURRENT%20ASSETS&level=1`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      setStockAsset1(response1.data.listofAccounts);
      const response2 = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=50101&accountName=Stock&level=2`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      setStockAsset2(response2.data.listofAccounts);
    } catch (error) {
      console.error(error);
    }
  };
  const showIncomeAccountModal = async () => {
    setIsIncomeAccountModalOpen(true);
    try {
      const response1 = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=60&accountName=SALES%20%2F%20REVENUE&level=1`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      //console.log(response1.data.listofAccounts);
      setIncomeAsset1(response1.data.listofAccounts);
      const response2 = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=60103&accountName=Other%20Income&level=2`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      //console.log(response2.data.listofAccounts);
      setIncomeAsset2(response2.data.listofAccounts);
    } catch (error) {
      console.error(error);
    }
  };
  const showExpenseAccountModal = async () => {
    setIsExpenseAccountModalOpen(true);
    try {
      const response1 = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=70&accountName=EXPENDITURES&level=1`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      //console.log(response1.data.listofAccounts);
      setExpenseAsset1(response1.data.listofAccounts);
      const response2 = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=70101&accountName=Cost%20of%20Labour&level=2`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      //console.log(response2.data.listofAccounts);
      setExpenseAsset2(response2.data.listofAccounts);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancel = () => {
    setIsCategoryModal(false);
    setIsTypeModal(false);
    setIsSizeModal(false);
    setIsUnitModal(false);
    setIsStockAccountModalOpen(false);
    setIsIncomeAccountModalOpen(false);
    setIsExpenseAccountModalOpen(false);
  };
  const handleDateChange = (e, value) => {
    setOpenDate(value);
    //console.log(value);
  };
  // Modal OnFinishes
  const onFinish = async (FormData) => {
    setIsCategoryModal(false);

    const data = {
      ...FormData,
      name: FormData.categoryName,
      type: "ProductCategory",
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
      shortName: FormData.shortName,
    };

    const response = await axios.get(
      Config.base_url +
      `DropdownData/GetDropdownDataByName/${CompanyID}?Type=ProductCategory&name=${FormData.categoryName}`,
      {
        headers: {
          Authorization: `Bearer ${AccessKey}`,
        },
      }
    );

    const length = response.data.dropdownData.length;
    if (length > 0) {
      message.warning("Category Already Added");
      // Reset the form field
      form.resetFields(["categoryName"]);
      setIsCategoryModal(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `DropdownData/AddDropdownData`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Category Added Successfully");
        // Reset the form field
        form.resetFields(["categoryName"]);
        GetCategoryDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsCategoryModal(false);
    }
  };
  const onFinishType = async (FormData) => {
    setIsTypeModal(false);

    const data = {
      ...FormData,
      name: FormData.typeName,
      type: "ProductType",
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
      shortName: "",
    };

    const response = await axios.get(
      Config.base_url +
      `DropdownData/GetDropdownDataByName/${CompanyID}?Type=ProductType&name=${FormData.typeName}`,
      {
        headers: {
          Authorization: `Bearer ${AccessKey}`,
        },
      }
    );

    const length = response.data.dropdownData.length;
    if (length > 0) {
      message.warning("Type Already Added");
      // Reset the form field
      form.resetFields(["typeName"]);
      setIsTypeModal(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `DropdownData/AddDropdownData`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Type Added Successfully");
        // Reset the form field
        form.resetFields(["typeName"]);
        GetTypeDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsTypeModal(false);
    }
  };
  const onFinishSize = async (FormData) => {
    setIsSizeModal(false);

    const data = {
      ...FormData,
      name: FormData.sizeName,
      type: "ProductSize",
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
      shortName: ""
    };

    const response = await axios.get(
      Config.base_url +
      `DropdownData/GetDropdownDataByName/${CompanyID}?Type=ProductSize&name=${FormData.sizeName}`,
      {
        headers: {
          Authorization: `Bearer ${AccessKey}`,
        },
      }
    );

    const length = response.data.dropdownData.length;
    if (length > 0) {
      message.warning("Size Already Added");
      // Reset the form field
      form.resetFields(["sizeName"]);
      setIsSizeModal(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `DropdownData/AddDropdownData`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Size Added Successfully");
        // Reset the form field
        form.resetFields(["sizeName"]);
        GetSizeDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSizeModal(false);
    }
  };
  const onFinishUnit = async (FormData) => {
    setIsUnitModal(false);

    const data = {
      ...FormData,
      name: FormData.unitName,
      type: "ProductUnit",
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
      shortName: ""
    };

    const response = await axios.get(
      Config.base_url +
      `DropdownData/GetDropdownDataByName/${CompanyID}?Type=ProductUnit&name=${FormData.unitName}`,
      {
        headers: {
          Authorization: `Bearer ${AccessKey}`,
        },
      }
    );

    const length = response.data.dropdownData.length;
    if (length > 0) {
      message.warning("Unit Already Added");
      // Reset the form field
      form.resetFields(["unitName"]);
      setIsUnitModal(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `DropdownData/AddDropdownData`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Unit Added Successfully");
        // Reset the form field
        form.resetFields(["unitName"]);
        GetUnitDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUnitModal(false);
    }
  };
  const onFinishStockAccount = async (FormData) => {
    setIsStockAccountModalOpen(false);

    const data = {
      ...FormData,
      accountCode: (
        parseInt(stockAsset[stockAsset.length - 1].accountCode) + 1
      ).toString(),
      accountDescription: FormData.nominalAccount,
      iLevel: 3,
      remarks: FormData.nominalAccount + FormData.description,
      year: 0,
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
    };

    const encodedNominalAccount = encodeURIComponent(FormData.nominalAccount);

    const apiUrl =
      Config.base_url +
      `AccountMain/GetBy/${CompanyID}?accountCode=50101&accountName=${encodedNominalAccount}&level=3`;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${AccessKey}`,
      },
    });
    //console.log(response);
    //console.log(response.data.listofAccounts);
    const length = response.data.listofAccounts.length;
    if (length > 0) {
      message.error("Nominal Account with same name already exist");
      AccountForm.resetFields();
      setIsStockAccountModalOpen(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `AccountMain/AddAccount`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Account Added Successfully");
        // Reset the form field
        AccountForm.resetFields();
        GetStockAccountDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsStockAccountModalOpen(false);
    }
  };
  const onFinishIncomeAccount = async (FormData) => {
    const data = {
      ...FormData,
      accountCode: (
        parseInt(incomeAsset[incomeAsset.length - 1].accountCode) + 1
      ).toString(),
      accountDescription: FormData.nominalAccount,
      iLevel: 3,
      remarks: FormData.nominalAccount + FormData.description,
      year: 0,
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
    };

    const encodedNominalAccount = encodeURIComponent(FormData.nominalAccount);

    const apiUrl =
      Config.base_url +
      `AccountMain/GetBy/${CompanyID}?accountCode=60103&accountName=${encodedNominalAccount}&level=3`;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${AccessKey}`,
      },
    });
    //console.log(response);
    //console.log(response.data.listofAccounts);

    const length = response.data.listofAccounts.length;
    if (length > 0) {
      message.error("Nominal Account with same name already exist");
      AccountForm.resetFields();
      setIsStockAccountModalOpen(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `AccountMain/AddAccount`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Account Added Successfully");
        // Reset the form field
        setIsIncomeAccountModalOpen(false);

        AccountForm.resetFields();
        GetIncomeAccountDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsIncomeAccountModalOpen(false);
    }
  };
  const onFinishExpenseAccount = async (FormData) => {
    const data = {
      ...FormData,
      accountCode: (
        parseInt(expenseAsset[expenseAsset.length - 1].accountCode) + 1
      ).toString(),
      accountDescription: FormData.nominalAccount,
      iLevel: 3,
      remarks: FormData.nominalAccount + FormData.description,
      year: 0,
      isActive: true,
      isDeleted: false,
      companyID: CompanyID,
    };

    const encodedNominalAccount = encodeURIComponent(FormData.nominalAccount);

    const apiUrl =
      Config.base_url +
      `AccountMain/GetBy/${CompanyID}?accountCode=60103&accountName=${encodedNominalAccount}&level=3`;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${AccessKey}`,
      },
    });
    //console.log(response);
    //console.log(response.data.listofAccounts);

    const length = response.data.listofAccounts.length;
    if (length > 0) {
      message.error("Nominal Account with same name already exist");
      AccountForm.resetFields();
      setIsExpenseAccountModalOpen(false);
      return;
    }

    try {
      const response = await axios.post(
        Config.base_url + `AccountMain/AddAccount`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (response.data.status_code === 1) {
        message.success("Account Added Successfully");
        // Reset the form field
        setIsExpenseAccountModalOpen(false);

        AccountForm.resetFields();
        GetExpenseAccountDropdownData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsExpenseAccountModalOpen(false);
    }
  };

  // Dynamic Product Name
  const isNameManuallyEdited = { current: false };

  const handleUpdateProductName = (changedValues, allValues) => {
    const { category, type, size } = allValues;

    // Check if the change is from a field that should update the name
    if (
      !isNameManuallyEdited.current &&
      (changedValues.category || changedValues.type || changedValues.size)
    ) {
      // Construct the new combined name
      const combinedName = [category, type, size].filter(Boolean).join(" ");

      // Set the new combined name in the form
      form.setFieldsValue({ name: combinedName });
      //console.log("Combined Name:", combinedName);
    }
  };

  const handleNameChange = () => {
    // Mark the name field as manually edited
    isNameManuallyEdited.current = true;
  };

  const handleFieldChange = () => {
    // Reset the flag if any other field changes
    isNameManuallyEdited.current = false;
  };

  const handleCategoryCodeChange = (value) => {
    console.log(value)
    const catCode = Category.find(
      (item) => item.name === value)
    console.log(catCode.shortName)
    setCategoryCode(catCode.shortName)
  }

  return (
    <>
      {/* Category Modal */}
      <Modal
        title="New Category"
        open={isCategoeyModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Name"
            name="categoryName"
            rules={[
              {
                required: true,
                message: "Please input the category name!",
              },
            ]}
          >
            <Input onFocus={(e) => e.target.select()} placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Short Code"
            name="shortName"
            rules={[
              {
                required: true,
                message: "Please input the category name!",
              },
            ]}
          >
            <Input onFocus={(e) => e.target.select()} placeholder="Name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "8px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Type Modal */}
      <Modal
        title="New Type"
        open={isTypeModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinishType}>
          <Form.Item
            label="Name"
            name="typeName"
            rules={[
              {
                required: true,
                message: "Please input the type!",
              },
            ]}
          >
            <Input onFocus={(e) => e.target.select()} placeholder="Name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "8px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Size Modal */}
      <Modal
        title="New Size"
        open={isSizeModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinishSize}>
          <Form.Item
            label="Name"
            name="sizeName"
            rules={[
              {
                required: true,
                message: "Please input the size!",
              },
            ]}
          >
            <Input onFocus={(e) => e.target.select()} placeholder="Name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "8px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Unit Modal */}
      <Modal
        title="New Unit"
        open={isUnitModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinishUnit}>
          <Form.Item
            label="Name"
            name="unitName"
            rules={[
              {
                required: true,
                message: "Please input the unit!",
              },
            ]}
          >
            <Input onFocus={(e) => e.target.select()} placeholder="Name" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "8px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Stock Asset Account Modal */}
      <Modal
        title="Nominal Account New"
        open={isStockAccountModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={750}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12}>
            <Form
              layout="vertical"
              onFinish={onFinishStockAccount}
              form={AccountForm}
            >
              {/* Nominal Account Category */}
              <Form.Item
                label="Nominal Account Category"
                name="nominalAccountCategory"
                rules={[
                  {
                    required: true,
                    message: "Please input the nominal account category!",
                  },
                ]}
              >
                <Select placeholder="Nominal Account Category">
                  {stockAssetLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin />
                    </Select.Option>
                  ) : (
                    stockAsset1.map((option) => (
                      <Select.Option
                        value={option.accountDescription}
                        key={option.id}
                      >
                        {option.accountDescription} ({option.accountCode})
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Section */}
              <Form.Item
                label="Section"
                name="section"
                rules={[
                  { required: true, message: "Please input the section!" },
                ]}
              >
                <Select placeholder="Section">
                  {stockAssetLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin />
                    </Select.Option>
                  ) : (
                    stockAsset2.map((option) => (
                      <Select.Option
                        value={option.accountDescription}
                        key={option.id}
                      >
                        {option.accountDescription} ({option.accountCode})
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Nominal Account */}
              <Form.Item
                label="Nominal Account"
                name="nominalAccount"
                rules={[
                  {
                    required: true,
                    message: "Please input the nominal account!",
                  },
                ]}
              >
                <Input onFocus={(e) => e.target.select()} placeholder="Nominal Account" />
              </Form.Item>

              {/* Code */}
              <Form.Item label="Code" name="code">
                <Input onFocus={(e) => e.target.select()} placeholder="Code" disabled />
              </Form.Item>

              {/* Description */}
              <Form.Item label="Description" name="description">
                <Input onFocus={(e) => e.target.select()} placeholder="Description" />
              </Form.Item>

              {/* Form Actions */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: "8px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Col>

          {/* Existing Nominal Accounts */}
          <Col xs={24} sm={12} md={12}>
            <Typography.Text strong>Existing Nominal Accounts</Typography.Text>
            <Table
              columns={columns}
              dataSource={stockAsset}
              size="small"
              loading={loading}
            />
          </Col>
        </Row>
      </Modal>

      {/* Income Account Modal */}
      <Modal
        title="Nominal Account New"
        open={isIncomeAccountModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={750}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12}>
            <Form
              layout="vertical"
              onFinish={onFinishIncomeAccount}
              form={AccountForm}
            >
              {/* Nominal Account Category */}
              <Form.Item
                label="Nominal Account Category"
                name="nominalAccountCategory"
                rules={[
                  {
                    required: true,
                    message: "Please input the nominal account category!",
                  },
                ]}
              >
                <Select placeholder="Nominal Account Category">
                  {incomeAssetLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin />
                    </Select.Option>
                  ) : (
                    incomeAsset1.map((option) => (
                      <Select.Option
                        value={option.accountDescription}
                        key={option.id}
                      >
                        {option.accountDescription} ({option.accountCode})
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Section */}
              <Form.Item
                label="Section"
                name="section"
                rules={[
                  { required: true, message: "Please input the section!" },
                ]}
              >
                <Select placeholder="Section">
                  {incomeAssetLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin />
                    </Select.Option>
                  ) : (
                    incomeAsset2.map((option) => (
                      <Select.Option
                        value={option.accountDescription}
                        key={option.id}
                      >
                        {option.accountDescription} ({option.accountCode})
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Nominal Account */}
              <Form.Item
                label="Nominal Account"
                name="nominalAccount"
                rules={[
                  {
                    required: true,
                    message: "Please input the nominal account!",
                  },
                ]}
              >
                <Input onFocus={(e) => e.target.select()} placeholder="Nominal Account" />
              </Form.Item>

              {/* Code */}
              <Form.Item label="Code" name="code">
                <Input onFocus={(e) => e.target.select()} placeholder="Code" disabled />
              </Form.Item>

              {/* Description */}
              <Form.Item label="Description" name="description">
                <Input onFocus={(e) => e.target.select()} placeholder="Description" />
              </Form.Item>

              {/* Form Actions */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: "8px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Col>

          {/* Existing Nominal Accounts */}
          <Col xs={24} sm={12} md={12}>
            <Typography.Text strong>Existing Nominal Accounts</Typography.Text>
            <Table
              columns={columns}
              dataSource={incomeAsset}
              size="small"
              loading={loading}
            />
          </Col>
        </Row>
      </Modal>

      {/* Expense Account Modal */}
      <Modal
        title="Nominal Account New"
        open={isExpenseAccountModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={750}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12}>
            <Form
              layout="vertical"
              onFinish={onFinishExpenseAccount}
              form={AccountForm}
            >
              {/* Nominal Account Category */}
              <Form.Item
                label="Nominal Account Category"
                name="nominalAccountCategory"
                rules={[
                  {
                    required: true,
                    message: "Please input the nominal account category!",
                  },
                ]}
              >
                <Select placeholder="Nominal Account Category">
                  {expenseAssetLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin />
                    </Select.Option>
                  ) : (
                    expenseAsset1.map((option) => (
                      <Select.Option
                        value={option.accountDescription}
                        key={option.id}
                      >
                        {option.accountDescription} ({option.accountCode})
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Section */}
              <Form.Item
                label="Section"
                name="section"
                rules={[
                  { required: true, message: "Please input the section!" },
                ]}
              >
                <Select placeholder="Section">
                  {expenseAssetLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin />
                    </Select.Option>
                  ) : (
                    expenseAsset2.map((option) => (
                      <Select.Option
                        value={option.accountDescription}
                        key={option.id}
                      >
                        {option.accountDescription} ({option.accountCode})
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>

              {/* Nominal Account */}
              <Form.Item
                label="Nominal Account"
                name="nominalAccount"
                rules={[
                  {
                    required: true,
                    message: "Please input the nominal account!",
                  },
                ]}
              >
                <Input onFocus={(e) => e.target.select()} placeholder="Nominal Account" />
              </Form.Item>

              {/* Code */}
              <Form.Item label="Code" name="code">
                <Input onFocus={(e) => e.target.select()} placeholder="Code" disabled />
              </Form.Item>

              {/* Description */}
              <Form.Item label="Description" name="description">
                <Input onFocus={(e) => e.target.select()} placeholder="Description" />
              </Form.Item>

              {/* Form Actions */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: "8px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Col>

          <Col xs={24} sm={12} md={12}>
            <Typography.Text strong>Existing Nominal Accounts</Typography.Text>
            <Table
              columns={columns}
              dataSource={expenseAsset}
              size="small"
              loading={loading}
            />
          </Col>
        </Row>
      </Modal>

      <div id="sub-menu-wrap">
        <h5>Production</h5>
        <ProductionMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header"><SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/products/manage">
                <ArrowLeftIcon />
              </NavLink>
              Edit Stock Products
            </h3>
          </div>
          {loadingSaveAccount ? (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <Form
              layout="vertical"
              size="medium"
              className="form-default"
              onFinish={handleSubmit}
              form={form}
              onValuesChange={(changedValues, allValues) => {
                handleFieldChange();
                handleUpdateProductName(changedValues, allValues);
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} md={9}>
                  <Form.Item
                    label="Product Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Product Name!",
                      },
                    ]}
                  >
                    <Input onFocus={(e) => e.target.select()} onChange={handleNameChange} />
                  </Form.Item>
                  <Form.Item name="category" label="Category">
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Category"
                      dropdownRender={(menufieldone) => (
                        <>
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={showCategoryModal}
                            >
                              Add Field
                            </Button>
                          </Space>
                          {menufieldone}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                        </>
                      )}
                      options={Category.map((fieldThreeitem) => ({
                        label: fieldThreeitem.name,
                        value: fieldThreeitem.name,
                      }))}
                      onSelect={handleCategoryCodeChange}
                    />
                  </Form.Item>

                  <Form.Item name="type" label="Type">
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Type"
                      dropdownRender={(menufieldone) => (
                        <>
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={showTypeModal}
                            >
                              Add Field
                            </Button>
                          </Space>
                          {menufieldone}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                        </>
                      )}
                      options={Type.map((fieldThreeitem) => ({
                        label: fieldThreeitem.name,
                        value: fieldThreeitem.name,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item name="size" label="Size">
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Size"
                      dropdownRender={(menufieldone) => (
                        <>
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={showSizeModal}
                            >
                              Add Field
                            </Button>
                          </Space>
                          {menufieldone}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                        </>
                      )}
                      options={Size.map((fieldThreeitem) => ({
                        label: fieldThreeitem.name,
                        value: fieldThreeitem.name,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item label="Code / Number" name="code">
                    <Input onFocus={(e) => e.target.select()} disabled placeholder="Code / Number" />
                  </Form.Item>
                  <Form.Item label="Sale Information" name="SaleInformation">
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item label="Notes" name="Notes">
                    <Input.TextArea />
                  </Form.Item>
                </Col>
                <Col xs={24} md={15}>
                  <Row gutter={[16]}>
                    <Col xs={24} sm={12} md={12}>
                      <Form.Item name="unit" label="Unit">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          placeholder="Select Unit"
                          dropdownRender={(menufieldone) => (
                            <>
                              <Space
                                style={{
                                  padding: "0 8px 4px",
                                }}
                              >
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={showUnitModal}
                                >
                                  Add Field
                                </Button>
                              </Space>
                              {menufieldone}
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                            </>
                          )}
                          options={Unit.map((fieldThreeitem) => ({
                            label: fieldThreeitem.name,
                            value: fieldThreeitem.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <Form.Item
                        name="stockAssetAccount"
                        label="Stock Asset Account"
                      >
                        <Select
                          style={{
                            width: "100%",
                          }}
                          placeholder="Stock Asset Acconunt"
                          dropdownRender={(menufieldone) => (
                            <>
                              <Space
                                style={{
                                  padding: "0 8px 4px",
                                }}
                              >
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={showStockAccountModal}
                                >
                                  Add Field
                                </Button>
                              </Space>
                              {menufieldone}
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                            </>
                          )}
                          options={stockAsset.map((fieldThreeitem) => ({
                            label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                            value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <Form.Item label="Low Stock Level" name="lowStockLevel">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <Form.Item name="incomeAccount" label="Income Account">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          placeholder="Stock Asset Acconunt"
                          dropdownRender={(menufieldone) => (
                            <>
                              <Space
                                style={{
                                  padding: "0 8px 4px",
                                }}
                              >
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={showIncomeAccountModal}
                                >
                                  Add Field
                                </Button>
                              </Space>
                              {menufieldone}
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                            </>
                          )}
                          options={incomeAsset.map((fieldThreeitem) => ({
                            label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                            value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <Form.Item label="Sale Price / Rate" name="salePrice">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <Form.Item name="expenseAccount" label="Expense Account">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          placeholder="Expense Account"
                          dropdownRender={(menufieldone) => (
                            <>
                              <Space
                                style={{
                                  padding: "0 8px 4px",
                                }}
                              >
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={showExpenseAccountModal}
                                >
                                  Add Field
                                </Button>
                              </Space>
                              {menufieldone}
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                            </>
                          )}
                          options={expenseAsset.map((fieldThreeitem) => ({
                            label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                            value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Date">
                        <DatePicker
                          defaultValue={
                            OpenDate ? dayjs(OpenDate, "YYYY-MM-DD") : dayjs()
                          }
                          onChange={handleDateChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Cost" name="cost">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Sale Discount (%)" name="saleDiscount">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item
                        label="Purchase Discount (%)"
                        name="purchaseDiscount"
                      >
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Weight" name="weight">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="GST Rate" name="gstRate">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item
                        label="Non Filer GST Rate"
                        name="nonFilerGstRate"
                      >
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="MRP Ex. Tax" name="mrpExTax">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="MRP Inc. Tax" name="mrpIncTax">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Bin Location" name="binLocation">
                        <Input onFocus={(e) => e.target.select()} placeholder="Bin Location" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Large Pack Size" name="largePackSize">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item label="Small Pack Size" name="smallPackSize">
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <Form.Item
                        label="Preferred Supplier"
                        name="preferredSupplier"
                      >
                        <Input onFocus={(e) => e.target.select()} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Collapse>
                <Collapse.Panel header="Advance Options">
                  <Typography.Text>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={10}>
                        <Form.Item
                          name="Image"
                          label="Add Image"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload.Dragger
                            name="file"
                            multiple={false}
                            listType="picture-card"
                            showUploadList={{
                              showPreviewIcon: true,
                              showRemoveIcon: true,
                            }}
                            onChange={onFileChange} // Handle file change
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={14}>
                        <Row gutter={[16]}>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field 1" name="field1">
                              <Input onFocus={(e) => e.target.select()} placeholder="Field 1" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field 2" name="field2">
                              <Input onFocus={(e) => e.target.select()} placeholder="Field 2" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field 3" name="field3">
                              <Input onFocus={(e) => e.target.select()} placeholder="Field 3" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field 4" name="field4">
                              <Input onFocus={(e) => e.target.select()} placeholder="Field 4" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field A" name="fieldA">
                              <Input.TextArea placeholder="Field A" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field B" name="fieldB">
                              <Input.TextArea placeholder="Field B" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field C" name="fieldC">
                              <Input.TextArea placeholder="Field C" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item label="Field D" name="fieldD">
                              <Input.TextArea placeholder="Field D" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Typography.Text>
                </Collapse.Panel>
              </Collapse>
              <br />
              <Form.Item>
                <Flex justify="space-between" align="center">
                  <NavLink to="/products/manage">
                    <Button type="default">Cancel</Button>
                  </NavLink>

                  <Button type="primary" htmlType="submit" loading={loading}>
                    Edit Stock Product
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}

export default EditStockProducts;
