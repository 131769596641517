import React, { useState, useRef, useEffect, useCallback } from "react";
import { debounce, throttle } from "lodash";
import {
  DeleteOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Divider,
  Space,
  Collapse,
  Dropdown,
  Spin,
  Table,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import PurchaseMenu from "./PurchaseMenu";
import { async } from "q";
import AddSupplierModal from "../Common/AddSupplierModal";

const { Option } = Select;

const AddSalesInvoice = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserName = localStorage.getItem("Full_Name");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [ExpenseForm] = Form.useForm();
  const [Productform] = Form.useForm();
  const [MainForm] = Form.useForm();
  const [ProductList, setProductList] = useState([]);

  const [loadingProduct, setloadingProduct] = useState(false);
  const [loadingMode, setloadingMode] = useState(false);
  const [loadingBank, setLoadingBank] = useState(false);
  const [ReceiptDate, setReceiptDate] = useState(null);
  const [AccountLoading, setAccountLoading] = useState(false);
  const [ListOfAccounts, setListOfAcconts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [CustomerLoading, setCustomerLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [SupplierID, setSupplierID] = useState("");
  const [open, setOpen] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalSaleTax, setTotalSaleTax] = useState(0);
  const [overallDiscount, setOverallDiscount] = useState(0);
  const [Complete, setComplete] = useState(false);

  const [SupplierForm] = Form.useForm();
  const [formMain] = Form.useForm();
  const [OpenDate, setOpenDate] = useState(null);
  const [termDays, setTermDays] = useState("");
  const [dueDate, setDueDate] = useState(null);

  const [ListOfRecords, setListOfRecords] = useState([]);
  const [ListOfProducts, setListOfProducts] = useState([]);
  const [ListOfBank, setListOfBank] = useState([]);
  const [BankMode, setBankMode] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedSupplier, setSelectedCustomer] = useState({});

  const [form] = Form.useForm();

  const handleSubmit = async (FormData) => {
    setLoading(true);
    if (!FormData.total || FormData.total < 0) {
      message.error("Please enter valid bill data");
      setLoading(false);
      return;
    }
    const paymentForm = await formMain.validateFields();
    if (paymentForm.amount && !paymentForm.bank) {
      message.error("Please Select Bank");
      setLoading(false);
      return;
    }
    if (paymentForm.amount > parseInt(FormData.total)) {
      message.error(
        "Payment Amount should less than or equal to Total Amount."
      );

      setLoading(false);
      return;
    }

    const fields = form.getFieldValue("users");
    if (fields.length === 0) {
      message.error("Please add at least one product.");
      setLoading(false);
      return;
    }
    const balance = (() => {
      let calculatedBalance = paymentForm.amount
        ? parseFloat(FormData.balance || 0) > 0
          ? parseFloat(FormData.total || 0) -
          parseFloat(FormData.balance || 0) -
          parseFloat(paymentForm.amount || 0)
          : parseFloat(FormData.total || 0) -
          parseFloat(paymentForm.amount || 0)
        : parseFloat(FormData.balance || 0) > 0
          ? parseFloat(FormData.total || 0) - parseFloat(FormData.balance || 0)
          : parseFloat(FormData.total || 0);
      return calculatedBalance < 0 ? 0 : calculatedBalance;
    })()
    const SupplierData = {
      ...FormData,
      date: OpenDate || dayjs().format("YYYY-MM-DD"),
      dueDate: dueDate || dayjs().format("YYYY-MM-DD"),
      field1: FormData.field1 || "",
      field2: FormData.field2 || "",
      field3: FormData.field3 || "",
      field4: FormData.field4 || "",
      notes: FormData.notes || "",
      billNumber: FormData.billNumber || 0,
      balance: balance,
      adjustedBalance: FormData.total - balance,
      purchaseType: "Credit",
      purchaseBy: UserName,
      extra1: "",
      companyID: CompanyID,
      isActive: true,
      isDeleted: false,
    };
    //console.log(SupplierData);
    try {
      const purchaseResponse = await axios.post(
        `${Config.base_url}PurchaseHead/AddPurchaseHead`,
        SupplierData,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      if (purchaseResponse.data.status_code === 1) {
        const billID = purchaseResponse.data.purchase.billID;

        const productData = fields.map((item) => ({
          ...item,
          field1: item.field1 || "",
          field2: item.field2 || "",
          weight: item.weight || 0,
          length: item.length || 0,
          quantity: item.quantity || 0,
          purchaseType: "Credit",
          purchaseBy: UserName,
          companyID: CompanyID,
          isActive: true,
          isDeleted: false,
          billID: billID,
        }));
        const productResponse = await axios.post(
          `${Config.base_url}PurchaseBody/AddPurchaseBody`,
          productData,
          {
            headers: {
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );

        if (productResponse.data.status_code === 1) {
          const updateSupplierData = {
            ...selectedSupplier,

            supplierOpeningBalance:
              purchaseResponse.data.purchase.balance === 0
                ? parseFloat(selectedSupplier.supplierOpeningBalance || 0) > 0
                  ? parseFloat(selectedSupplier.supplierOpeningBalance || 0) -
                  parseFloat(FormData.total || 0) +
                  parseFloat(paymentForm.amount || 0)
                  : parseFloat(selectedSupplier.supplierOpeningBalance || 0)
                : parseFloat(selectedSupplier.supplierOpeningBalance || 0) -
                parseFloat(FormData.total || 0) +
                parseFloat(paymentForm.amount || 0),
          };

          await axios.patch(
            Config.base_url +
            `CustomerSupplier/UpdateRecord/${selectedSupplier.id}`,
            updateSupplierData,
            {
              headers: {
                Authorization: `Bearer ${AccessKey}`,
              },
            }
          );

          const updateProduct = fields.map((item) => ({
            ...item,
            name: FormData.name || "",
            type: FormData.type || "",
            date: OpenDate || dayjs().format("YYYY-MM-DD"),
            cost: FormData.cost || 0,
            unit: FormData.unit || "",
            notes: FormData.notes || "",
            weight: FormData.weight || 0,
            field1: FormData.field1 || "",
            field2: FormData.field2 || "",
            field3: FormData.field3 || "",
            field4: FormData.field4 || "",
            fieldA: FormData.fieldA || "",
            fieldB: FormData.fieldB || "",
            fieldC: FormData.fieldC || "",
            fieldD: FormData.fieldD || "",
            category: FormData.category || "",
            maxRRExTax: FormData.maxRRExTax || 0,
            salePrice: FormData.salePrice || 0,
            openingRate: FormData.openingRate || 0,
            saleDiscount: FormData.saleDiscount || 0,
            stockAssetAccount: FormData.stockAssetAccount || "",
            lowStockLevel: FormData.lowStockLevel || 0,
            incomeAccount: FormData.incomeAccount || "",
            expenseAccount: FormData.expenseAccount || "",
            purchaseDiscount: FormData.purchaseDiscount || 0,
            gstRate: FormData.gstRate || "",
            saleInformation: FormData.saleInformation || "",
            nonFilerGSTRate: FormData.nonFilerGSTRate || "",
            maxRRIncTax: FormData.maxRRIncTax || 0,
            binLocation: FormData.binLocation || "",
            largePackSize: FormData.largePackSize || 0,
            smallPackSize: FormData.smallPackSize || 0,
            prefferedSupplier: FormData.prefferedSupplier || "",
            productType: "Stock",
            size: FormData.size || "",
            companyID: CompanyID,
            defaultUnit: "",
            id: item.selectedProduct,
            openingQuantity:
              item.openingQuantity -
              (item.defaultUnit === "Quantity"
                ? item.quantity
                : item.defaultUnit === "Weight"
                  ? item.weight
                  : item.length),
            isActive: true,
            isDeleted: false,
            categoryCode: ""

          }));

          //console.log(updateProduct)
          await axios.patch(
            Config.base_url + `Product/UpdateRecords`,
            updateProduct,
            {
              headers: {
                Authorization: `Bearer ${AccessKey}`,
              },
            }
          );

          const AdjustedBalance = purchaseResponse.data.purchase.adjustedBalance;
          if (paymentForm.amount || (AdjustedBalance > 0)) {
            try {
              const Payment = FormData.total + AdjustedBalance;
              const PaymentData = {
                ...FormData,
                ...paymentForm,

                totalDiscount: 0,
                amount: Payment <= FormData.total ? Payment : FormData.total,
                totalPayment: Payment <= FormData.total ? Payment : FormData.total,
                totalOpenBalance: FormData.total,
                total: Payment <= FormData.total ? Payment : FormData.total,
                mode: paymentForm.mode || "",
                date: OpenDate || dayjs().format("YYYY-MM-DD"),
                field1: paymentForm.field1 || "",
                field2: paymentForm.field2 || "",
                field3: paymentForm.field3 || "",
                field4: paymentForm.field4 || "",
                fieldA: paymentForm.fieldA || "",
                fieldB: paymentForm.fieldB || "",
                fieldC: paymentForm.fieldC || "",
                fieldD: paymentForm.fieldD || "",
                notes: paymentForm.notes || "",
                refNo: paymentForm.refNo || "",
                purchaseType: "Receipt",
                purchaseBy: UserName,
                extra2: "",
                companyID: CompanyID,
                isActive: true,
                isDeleted: false,
                mailingAddress: FormData.address || "",
              };

              const paymentResponse = await axios.post(
                `${Config.base_url}PaymentHead/AddPaymentHead`,
                PaymentData,
                {
                  headers: {
                    Authorization: `Bearer ${AccessKey}`,
                  },
                }
              );

              if (paymentResponse.data.status_code === 1) {
                const payment = paymentResponse.data.paymentHead.voucherNo;
                const billID = purchaseResponse.data.purchase.billID;
                const paymentBodyData = [
                  {
                    discount: 0,
                    openBalance: FormData.total,
                    payment: Payment <= FormData.total ? Payment : FormData.total,
                    amount: FormData.total,
                    total: Payment <= FormData.total ? Payment : FormData.total,
                    date: OpenDate || dayjs().format("YYYY-MM-DD"),
                    dueDate: dueDate || dayjs().format("YYYY-MM-DD"),
                    billNo: FormData.billNo || "",
                    field1: FormData.field1 || "",
                    field2: FormData.field2 || "",
                    field3: FormData.field3 || "",
                    field4: FormData.field4 || "",
                    purchaseType: "Receipt",
                    purchaseBy: UserName,
                    companyID: CompanyID,
                    isActive: true,
                    isDeleted: false,
                    billID: billID,
                    voucherNo: payment,
                    id: 0,
                  },
                ];

                const ProductResponse = await axios.post(
                  Config.base_url + `PaymentBody/AddPaymentBody`,
                  paymentBodyData,
                  {
                    headers: {
                      Authorization: `Bearer ${AccessKey}`,
                    },
                  }
                );
                if (ProductResponse.data.status_code === 1) {
                  message.success(
                    <>
                      Credit Bill Added Successfully Against <br />
                      Bill ID: <span style={{ color: "blue" }}>{billID}</span>
                    </>
                  );
                  setProductList([]);
                  setLoading(false);
                  // navigate("/purchases/purchase-bills");
                  SupplierForm.resetFields();
                  form.resetFields();
                  formMain.resetFields();
                } else {
                  message.error("Error in Adding Purchase Bill");
                  setLoading(false);
                }
              } else {
                message.error("Error in Adding Credit Bill");
                setLoading(false);
              }
            } catch (error) {
              console.error(error);
              message.error("Error in Adding Credit Bill");
              setLoading(false);
            }
          } else {
            message.success(
              <>
                Credit Bill Added Successfully Against <br />
                Bill ID: <span style={{ color: "blue" }}>{billID}</span>
              </>
            );
            setProductList([]);
            setLoading(false);
            // navigate("/purchases/purchase-bills");
            SupplierForm.resetFields();
            form.resetFields();
            formMain.resetFields();
          }
        } else {
          message.error("Error in Adding Product Data");
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      message.error("Error in Adding Purchase Head");
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Add Credit Bill";
    fetchSupplier();
    fetchProducts();
    fetchBankAccounts();
    fetchBankMode();
    fetchExpense();
  }, []);

  const fetchProducts = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}Product/GetBy/${CompanyID}?&pageNumber=1&pageSize=1000000`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        //console.log(response.data)
        setProductLoading(false);
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setProductLoading(false);

        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setProductLoading(false);

      setListOfProducts([]);
    }
  };

  const fetchSupplier = async () => {
    setCustomerLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}CustomerSupplier/GetSuppliersBy/${CompanyID}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      //console.log("API response:", response.data);

      if (response.data && response.data.status_code === 1) {
        setListOfRecords(response.data.listofSuppliers || []);
        setTotalRecords(response.data.totalRecords || 0);
        setCustomerLoading(false);
      } else {
        console.warn(
          "No data or error status_code:",
          response.data.status_code
        );
        setCustomerLoading(false);
        setListOfRecords([]);
      }
    } catch (error) {
      setCustomerLoading(false);
      console.error(
        "Error fetching data:",
        error.response?.data || error.message
      );
      setListOfRecords([]);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setListOfBank(response.data.listofAccounts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBankMode = async () => {
    try {
      const response = await axios.get(
        Config.base_url + `DropdownData/GetDropdownData/0?Type=BankMode`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setBankMode(response.data.dropdownData || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExpense = async () => {
    setAccountLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}AccountMain/GetBy/${CompanyID}?accountCode=70&level=3`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      //console.log(response.data);
      if (response.data && response.data.status_code === 1) {
        setAccountLoading(false);
        setListOfAcconts(response.data.listofAccounts || []);
      } else {
        setAccountLoading(false);

        setListOfAcconts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAccountLoading(false);

      setListOfAcconts([]);
    }
  };

  const handleOk = (FormData) => {
    setLoading(true);
    setOpen(false);
    setLoading(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const SupplierDetail = () => {
    const onTermDaysChange = (e) => {
      const value = e.target.value;

      setTermDays(value);
      const days = parseInt(value, 10);
      if (!isNaN(days)) {
        const newDueDate = dayjs(OpenDate).add(days, "day").format("YYYY-MM-DD");
        //console.log(newDueDate);
        setDueDate(newDueDate);
        setTermDays(days);
      } else {
        setTermDays(0);
      }
    };

    const handleSupplierChange = (value) => {
      let code = value.match(/\((\d+)/);
      code = code ? code[1] : null;
      const supplier = ListOfRecords.find(
        (record) => record.accountNo === code
      );
      setSelectedCustomer(supplier);
      //console.log(supplier);
      if (supplier) {
        SupplierForm.setFieldsValue({
          address: supplier.billingAddress,
          creditLimit: supplier.creditLimit,
          balance: supplier.supplierOpeningBalance,
          SupplierAccountCode: supplier.accountNo,
        });
      }
    };

    const handleDateChange = (e, value) => {
      setOpenDate(value);
    };

    const handleDueDateChange = (e, value) => {
      setDueDate(value);
    };
    return (
      <>
        <Form layout="vertical" form={SupplierForm} onFinish={handleSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="supplierName"
                label="Supplier"
                rules={[
                  {
                    required: true,
                    message: "Please select the supplier name.",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Supplier"
                  dropdownRender={(menufieldone) => (
                    <>
                      {menufieldone}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={() => setOpen(true)}
                        >
                          Add Field
                        </Button>
                      </Space>
                    </>
                  )}
                  loading={CustomerLoading}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent={
                    CustomerLoading ? <Spin size="small" /> : null
                  }
                  options={ListOfRecords.map((record) => ({
                    label: `${record.businessName.trim()} (${record.isSupplier && parseInt(record.accountCode) < 9000
                      ? record.accountNo + " (S)"
                      : record.isCustomer &&
                        parseInt(record.accountCode) > 9000
                        ? record.accountNo + " (C)"
                        : record.accountNo
                      })`.trim(),
                    value: `${record.businessName.trim()} (${record.isSupplier && parseInt(record.accountCode) < 9000
                      ? record.accountNo + " (S)"
                      : record.isCustomer &&
                        parseInt(record.accountCode) > 9000
                        ? record.accountNo + " (C)"
                        : record.accountNo
                      })`.trim(),
                  }))}
                  onSelect={handleSupplierChange}
                />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item name="date" label="Date" required>
                    <DatePicker
                      defaultValue={OpenDate ? dayjs(OpenDate, "YYYY-MM-DD") : dayjs()}
                      style={{ width: "100%" }}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                </Col>
                <Form.Item name="SupplierAccountCode" label="Term Days" hidden>
                  <Input />
                </Form.Item>
                <Col xs={24} md={12}>
                  <Form.Item name="termDays" label="Term Days">
                    <Input type="number" onChange={onTermDaysChange} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="dueDate" label="Due Date" required>
                    <DatePicker
                      defaultValue={
                        OpenDate ?
                          (termDays && termDays > 0 ? dayjs(OpenDate).add(termDays, "days") : dayjs(OpenDate))
                          : dayjs()
                      }
                      style={{ width: "100%" }}
                      onChange={handleDueDateChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="billNumber" label="Bill No.">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={24}>
                  <Form.Item name="creditLimit" label="Credit Limit">
                    <Input readOnly disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item name="balance" label="Balance">
                    <Input readOnly disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const ProductDetail = () => {
    const handleSelectChange = (value, index) => {
      const selectedProduct = ListOfProducts.find(
        (item) => item.name === value
      );
      //console.log(selectedProduct);
      if (selectedProduct) {
        const fields = form.getFieldValue("users");
        const quantity = fields[index].quantity || 0;
        const weight = fields[index].quantity || 0;
        const length = fields[index].quantity || 0;
        const rate = selectedProduct.salePrice;
        const amount = (quantity * rate).toFixed(2);
        const discount = (amount * selectedProduct.saleDiscount) / 100;
        const saleTax = (selectedProduct.gstRate * amount) / 100;
        const net = (amount - discount + saleTax).toFixed(2);

        form.setFieldsValue({
          users: fields.map((field, i) =>
            i === index
              ? {
                ...field,
                description: `${selectedProduct.name} ${selectedProduct.saleInformation}`,
                unit: selectedProduct.unit,
                weight,
                length,
                quantity,
                rate,
                amount,
                discPercentege: selectedProduct.saleDiscount,
                discount,
                taxRate: selectedProduct.gstRate,
                saleTax,
                net,
                defaultUnit: selectedProduct.defaultUnit,
                openingQuantity: selectedProduct.openingQuantity,
                selectedProduct: selectedProduct.id,
              }
              : field
          ),
        });
        debouncedHandleFormChange();
      } else {
        console.error("Selected product not found in ListOfProducts:", value);
      }
    };

    const handleQuantityChange = (e, index) => {
      const quantity = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(quantity) || quantity <= 0) {
        //console.log("Invalid quantity value");
        return;
      }

      if (formInstance.defaultUnit === "Quantity") {
        const amount = (quantity * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          quantity,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Quantity");
      }
    };

    const handleWeightChange = (e, index) => {
      const weight = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(weight) || weight <= 0) {
        //console.log("Invalid weight value");
        return;
      }

      if (formInstance.defaultUnit === "Weight") {
        const amount = (weight * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          weight,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Weight");
      }
    };

    const handleLengthChange = (e, index) => {
      const length = parseFloat(e.target.value);
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      if (isNaN(length) || length <= 0) {
        //console.log("Invalid length value");
        return;
      }

      if (formInstance.defaultUnit === "Length") {
        const amount = (length * formInstance.rate).toFixed(2) || 0;
        const discountAmt = (formInstance.discPercentege * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          length,
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        //console.log("Rate unit is not Length");
      }
    };

    const handleRateChange = (e, index) => {
      const fields = form.getFieldValue("users");

      const formInstance = fields[index];
      const rate = parseFloat(e.target.value) || 0;
      const quantity = parseFloat(formInstance.quantity) || 1;
      const length = parseFloat(formInstance.length) || 1;
      const weight = parseFloat(formInstance.weight) || 1;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      if (formInstance.defaultUnit === "Length") {
        const amount = length * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else if (formInstance.defaultUnit === "Weight") {
        const amount = weight * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else if (formInstance.defaultUnit === "Quantity") {
        const amount = quantity * rate;
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        form.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const handleDiscountChange = (e, index) => {
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      //console.log(formInstance);

      const discountPercentage = parseFloat(e.target.value) || 0;
      if (discountPercentage > 100) {
        message.error("Discount cannot be more than 100.");
        return;
      }

      // Ensure amount is not recalculated here
      const amount = parseFloat(formInstance.amount) || 0;
      const gst = parseFloat(formInstance.saleTax) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const net = (amount - discountAmt + gst).toFixed(2);

        fields[index] = {
          ...fields[index],
          discount: discountAmt,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        console.error("Invalid amount");
      }
    };

    const handleTaxRateChange = (e, index) => {
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];

      const taxRate = parseFloat(e.target.value) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;
      const quantity = parseFloat(formInstance.quantity) || 0;
      const rate = parseFloat(formInstance.rate) || 0;
      const amount = parseFloat(formInstance.amount) || 0; // Get the existing amount

      if (quantity > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const taxAmt = (taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + taxAmt).toFixed(2);

        fields[index] = {
          ...fields[index],
          saleTax: taxAmt,
          net,
        };

        form.setFieldsValue({
          users: fields,
        });

        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const handleUnitChange = (value, index) => {
      const fields = form.getFieldValue("users");
      const formInstance = fields[index];
      const rate = parseFloat(formInstance.rate) || 0;
      const quantity = parseFloat(formInstance.quantity) || 0;
      const length = parseFloat(formInstance.length) || 0;
      const weight = parseFloat(formInstance.weight) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      let amount = 0;
      let discountAmt = 0;
      let tax = 0;
      let net = 0;

      if (value === "Length") {
        amount = length * rate;
      } else if (value === "Weight") {
        amount = weight * rate;
      } else if (value === "Quantity") {
        amount = quantity * rate;
      }

      discountAmt = (discountPercentage * amount) / 100 || 0;
      tax = (formInstance.taxRate * amount) / 100 || 0;
      net = (amount - discountAmt + tax).toFixed(2);

      fields[index] = {
        ...fields[index],
        amount,
        discount: discountAmt,
        saleTax: tax,
        net,
      };

      form.setFieldsValue({
        users: fields,
      });

      debouncedHandleFormChange(); // Trigger the form change handler after setting values
    };

    const columns = (remove) => [
      {
        title: "Product / Services",
        dataIndex: "product",
        key: "product",
        width: 150,
      },

      { title: "Unit", dataIndex: "unit", key: "unit", width: 150 },
      { title: "Quantity", dataIndex: "quantity", key: "quantity", width: 100 },
      { title: "Weight", dataIndex: "weight", key: "weight", width: 100 },
      { title: "Length", dataIndex: "length", key: "length", width: 100 },
      { title: "Rate", dataIndex: "rate", key: "rate", width: 230 },
      {
        title: "Rate Unit",
        dataIndex: "defaultUnit",
        key: "defaultUnit",
        width: 180,
      },
      { title: "Amount", dataIndex: "amount", key: "amount", width: 250 },
      // {
      //   title: "Disc %",
      //   dataIndex: "discPercentege",
      //   key: "discPercentege",
      //   width: 100,
      // },
      // { title: "Discount", dataIndex: "discount", key: "discount", width: 150 },
      // { title: "Tax Rate %", dataIndex: "taxRate", key: "taxRate", width: 150 },
      // { title: "Sale Tax", dataIndex: "saleTax", key: "saleTax", width: 200 },
      // { title: "Net ", dataIndex: "net", key: "net", width: 250 },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link to={`#/`} onClick={() => remove(key)} className="red">
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];
    const debouncedHandleFormChange = useCallback(
      debounce(() => {
        const fields = form.getFieldValue("users");
        const amounts = fields.map((item) => parseFloat(item.amount) || 0);
        const discount = fields.map((item) => parseFloat(item.discount) || 0);
        const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
        const net = fields.map((item) => parseFloat(item.net) || 0);

        const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
        const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
        const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
        const total = net.reduce((sum, value) => sum + value, 0);

        setTotalDiscount(totalAmount);
        SupplierForm.setFieldsValue({
          subTotal: totalAmount,
          totalDiscount: totalDiscount,
          totalSaleTax: totalSaleTax,
          total: total,
        });
      }, 1000),
      []
    );

    return (
      <>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={handleSubmit}
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      key,
                      product: (
                        <Form.Item
                          {...restField}
                          name={[name, "product"]}
                          fieldKey={[fieldKey, "product"]}
                          style={{ width: "300px" }}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleSelectChange(value, index);
                            }}
                            placeholder="Product / Services"
                            variant="borderless"
                            loading={productLoading}
                            options={ListOfProducts.map((record) => ({
                              label: (
                                <>
                                  {record.name} <br /> {record.openingQuantity}
                                </>
                              ),
                              value: record.name,
                            }))}
                          />
                        </Form.Item>
                      ),

                      unit: (
                        <Form.Item
                          {...restField}
                          name={[name, "unit"]}
                          fieldKey={[fieldKey, "unit"]}
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      quantity: (
                        <Form.Item
                          {...restField}
                          name={[name, "quantity"]}
                          fieldKey={[fieldKey, "quantity"]}
                        >
                          <Input
                            placeholder="Quantity"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleQuantityChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      weight: (
                        <Form.Item
                          {...restField}
                          name={[name, "weight"]}
                          fieldKey={[fieldKey, "weight"]}
                        >
                          <Input
                            placeholder="Weight"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleWeightChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      length: (
                        <Form.Item
                          {...restField}
                          name={[name, "length"]}
                          fieldKey={[fieldKey, "length"]}
                        >
                          <Input
                            placeholder="Length"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleLengthChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      rate: (
                        <Form.Item
                          {...restField}
                          name={[name, "rate"]}
                          fieldKey={[fieldKey, "rate"]}
                        >
                          <Input
                            placeholder="Rate"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleRateChange(e, index)}
                          />
                        </Form.Item>
                      ),
                      defaultUnit: (
                        <Form.Item
                          {...restField}
                          name={[name, "defaultUnit"]}
                          fieldKey={[fieldKey, "defaultUnit"]}
                        >
                          <Select
                            variant="borderless"
                            placeholder="Rate Unit"
                            onChange={(value) => {
                              handleUnitChange(value, index);
                            }}
                          >
                            <Option value="Quantity">Quantity</Option>
                            <Option value="Weight">Weight</Option>
                            <Option value="Length">Length</Option>
                          </Select>
                        </Form.Item>
                      ),
                      amount: (
                        <Form.Item
                          {...restField}
                          name={[name, "amount"]}
                          fieldKey={[fieldKey, "amount"]}
                        >
                          <Input
                            placeholder="Amount"
                            readOnly
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      // discPercentege: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "discPercentege"]}
                      //     fieldKey={[fieldKey, "discPercentege"]}
                      //     onChange={(e) => handleDiscountChange(e, index)}
                      //   >
                      //     <Input placeholder="Disc %" variant="borderless" />
                      //   </Form.Item>
                      // ),
                      // discount: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "discount"]}
                      //     fieldKey={[fieldKey, "discount"]}
                      //   >
                      //     <Input placeholder="Discount" variant="borderless" />
                      //   </Form.Item>
                      // ),
                      // taxRate: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "taxRate"]}
                      //     fieldKey={[fieldKey, "taxRate"]}
                      //     onChange={(e) => handleTaxRateChange(e, index)}
                      //   >
                      //     <Input
                      //       placeholder="Tax Rate %"
                      //       variant="borderless"
                      //     />
                      //   </Form.Item>
                      // ),
                      // saleTax: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "saleTax"]}
                      //     fieldKey={[fieldKey, "saleTax"]}
                      //   >
                      //     <Input placeholder="Sale Tax" variant="borderless" />
                      //   </Form.Item>
                      // ),
                      openingQuantity: (
                        <Form.Item
                          {...restField}
                          name={[name, "openingQuantity"]}
                          fieldKey={[fieldKey, "openingQuantity"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      selectedProduct: (
                        <Form.Item
                          {...restField}
                          name={[name, "selectedProduct"]}
                          fieldKey={[fieldKey, "selectedProduct"]}
                          hidden
                        >
                          <Input
                            placeholder="Unit"
                            variant="borderless"
                            onFocus={(e) => e.target.select()}
                          />
                        </Form.Item>
                      ),
                      // net: (
                      //   <Form.Item
                      //     {...restField}
                      //     name={[name, "net"]}
                      //     fieldKey={[fieldKey, "net"]}
                      //   >
                      //     <Input
                      //       placeholder="Net"
                      //       variant="borderless"
                      //       readOnly
                      //     />
                      //   </Form.Item>
                      // ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                />

                <Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const AddPayment = () => {
    const handleAmountChange = () => {
      const amount = formMain.getFieldValue("amount");
      const total = SupplierForm.getFieldValue("total");
      if (amount > total) {
        message.error("Amount cannot be greater than total amount");
        formMain.setFieldsValue({
          amount: 0,
        });
      }
    };
    return (
      <>
        <Form layout="vertical" form={formMain} onFinish={handleSubmit}>
          <Row gutter={[8, 0]}>
            <Col xs={24} md={4}>
              <Form.Item name="date" label="Date">
                <DatePicker
                  defaultValue={
                    ReceiptDate == null
                      ? dayjs()
                      : dayjs(ReceiptDate, "YYYY-MM-DD")
                  }
                  style={{ width: "100%" }}
                  onChange={(value) => setReceiptDate(value)}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item name="bank" label="Bank" initialValue="Cash on hand (501081001)">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Bank"
                  loading={loadingBank}
                  options={ListOfBank.map((option) => ({
                    label: `${option.accountDescription} (${option.accountCode})`,
                    value: `${option.accountDescription} (${option.accountCode})`,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Row gutter={[8, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item name="mode" label="Mode" initialValue="Cash">
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Mode"
                      loading={loadingMode}
                      options={BankMode.map((fieldThreeitem) => ({
                        label: fieldThreeitem.name,
                        value: fieldThreeitem.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="refNo" label="Ref No.">
                    <Input
                      placeholder="Ref No."
                      onFocus={(e) => e.target.select()}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={4}>
              <Form.Item name="amount" label="Amount">
                <Input
                  placeholder="Amount"
                  onFocus={(e) => e.target.select()}
                  onChange={handleAmountChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const ExpenseDetail = () => {
    const handleAmountChange = (e, index) => {
      const fields = ExpenseForm.getFieldValue("users");

      const formInstance = fields[index];
      const amount = parseFloat(e.target.value) || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (formInstance.taxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          amount,
          discount: discountAmt,
          saleTax: Tax,
          net,
        };
        ExpenseForm.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        message.error("Please Enter Amount");
      }
    };

    const handleDiscountChange = (e, index) => {
      const fields = ExpenseForm.getFieldValue("users");

      const formInstance = fields[index];
      const amount = formInstance.amount;
      const discountPercentage = parseFloat(e.target.value) || 0;
      if (discountPercentage > 100) {
        message.error("Discount cannot more than 100.");
        return;
      }
      const gst = parseFloat(formInstance.saleTax) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const net = (amount - discountAmt + gst).toFixed(2);

        fields[index] = {
          ...fields[index],
          discount: discountAmt,
          net,
        };
        ExpenseForm.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const handleTaxRateChange = (e, index) => {
      const fields = ExpenseForm.getFieldValue("users");

      const formInstance = fields[index];
      const TaxRate = parseFloat(e.target.value);
      const amount = formInstance.amount || 0;
      const discountPercentage = parseFloat(formInstance.discPercentege) || 0;

      if (amount > 0) {
        const discountAmt = (discountPercentage * amount) / 100 || 0;
        const Tax = (TaxRate * amount) / 100 || 0;
        const net = (amount - discountAmt + Tax).toFixed(2);

        fields[index] = {
          ...fields[index],
          saleTax: Tax,
          net,
        };
        ExpenseForm.setFieldsValue({
          users: fields,
        });
        debouncedHandleFormChange();
      } else {
        console.error("Invalid product selection or quantity");
      }
    };

    const columns = (remove) => [
      {
        title: "Expense",
        dataIndex: "expense",
        key: "expense",
        width: 200,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 200,
      },
      { title: "Amount", dataIndex: "amount", key: "amount", width: 130 },
      {
        title: "Disc %",
        dataIndex: "discPercentege",
        key: "discPercentege",
        width: 150,
      },
      { title: "Discount", dataIndex: "discount", key: "discount", width: 150 },
      { title: "Tax Rate %", dataIndex: "taxRate", key: "taxRate", width: 120 },
      { title: "Sale Tax", dataIndex: "saleTax", key: "saleTax", width: 120 },
      { title: "Net", dataIndex: "net", key: "net", width: 150 },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 110,
        render: (_, { key }) => (
          <ul className="inline-action">
            <li>
              <Link to={`#/`} onClick={() => remove(key)} className="red">
                <DeleteOutlined />
              </Link>
            </li>
          </ul>
        ),
      },
    ];
    const debouncedHandleFormChange = useCallback(
      debounce(() => {
        const fields = ExpenseForm.getFieldValue("users");
        const amounts = fields.map((item) => parseFloat(item.amount) || 0);
        const discount = fields.map((item) => parseFloat(item.discount) || 0);
        const tax = fields.map((item) => parseFloat(item.saleTax) || 0);
        const net = fields.map((item) => parseFloat(item.net) || 0);

        const totalAmount = amounts.reduce((sum, value) => sum + value, 0);
        const totalDiscount = discount.reduce((sum, value) => sum + value, 0);
        const totalSaleTax = tax.reduce((sum, value) => sum + value, 0);
        const total = net.reduce((sum, value) => sum + value, 0);

        setTotalDiscount(totalAmount);
        SupplierForm.setFieldsValue({
          subTotal: totalAmount,
          totalDiscount: totalDiscount,
          totalSaleTax: totalSaleTax,
          total: total,
        });
      }, 2000),
      []
    );

    return (
      <>
        <Form
          form={ExpenseForm}
          name="dynamic_form_nest_item"
          autoComplete="off"
          initialValues={{
            users: [{}],
          }}
        >
          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Table
                  dataSource={fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => ({
                      key,
                      expense: (
                        <Form.Item
                          {...restField}
                          name={[name, "expense"]}
                          fieldKey={[fieldKey, "expense"]}
                        >
                          <Select
                            variant="borderless"
                            placeholder="Expense"
                            showSearch
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: 180 }}
                            options={ListOfAccounts.map((fieldThreeitem) => ({
                              label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                              value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                            }))}
                          ></Select>
                        </Form.Item>
                      ),
                      description: (
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          fieldKey={[fieldKey, "description"]}
                        >
                          <Input
                            placeholder="Description"
                            variant="borderless"
                          />
                        </Form.Item>
                      ),
                      amount: (
                        <Form.Item
                          {...restField}
                          name={[name, "amount"]}
                          fieldKey={[fieldKey, "amount"]}
                          onChange={(e) => handleAmountChange(e, index)}
                        >
                          <Input placeholder="Amount" variant="borderless" />
                        </Form.Item>
                      ),
                      discPercentege: (
                        <Form.Item
                          {...restField}
                          name={[name, "discPercentege"]}
                          fieldKey={[fieldKey, "discPercentege"]}
                          onChange={(e) => handleDiscountChange(e, index)}
                        >
                          <Input placeholder="Disc %" variant="borderless" />
                        </Form.Item>
                      ),
                      discount: (
                        <Form.Item
                          {...restField}
                          name={[name, "discount"]}
                          fieldKey={[fieldKey, "discount"]}
                        >
                          <Input placeholder="Discount" variant="borderless" />
                        </Form.Item>
                      ),
                      taxRate: (
                        <Form.Item
                          {...restField}
                          name={[name, "taxRate"]}
                          fieldKey={[fieldKey, "taxRate"]}
                          onChange={(e) => handleTaxRateChange(e, index)}
                        >
                          <Input
                            placeholder="Tax Rate %"
                            variant="borderless"
                          />
                        </Form.Item>
                      ),
                      saleTax: (
                        <Form.Item
                          {...restField}
                          name={[name, "saleTax"]}
                          fieldKey={[fieldKey, "saleTax"]}
                        >
                          <Input placeholder="Sale Tax" variant="borderless" />
                        </Form.Item>
                      ),
                      net: (
                        <Form.Item
                          {...restField}
                          name={[name, "net"]}
                          fieldKey={[fieldKey, "net"]}
                        >
                          <Input placeholder="Net" variant="borderless" />
                        </Form.Item>
                      ),
                      action: (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ),
                    })
                  )}
                  columns={columns(remove)}
                  pagination={false}
                  size="small"
                />

                <Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  const items = [
    {
      label: "Approve and New",
      key: "1",
    },
    {
      label: "Approve and Print",
      key: "2",
    },
    {
      label: "Approve and Email",
      key: "3",
    },
  ];

  const handleOverAllDiscountChange = (e) => {
    const discount = e.target.value;
    const subTotal = SupplierForm.getFieldValue("subTotal");
    const totalSaleTax = SupplierForm.getFieldValue("totalSaleTax");
    const totalDiscount = SupplierForm.getFieldValue("totalDiscount");

    const SubTotalAfterDiscount = (subTotal * discount) / 100 || 0;

    const netTotal = subTotal + totalSaleTax - totalDiscount - discount;

    SupplierForm.setFieldsValue({
      total: netTotal.toFixed(2),
    });
  };

  return (
    <>
      <AddSupplierModal
        show={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={CustomerLoading}
        SupplierID={SupplierID}
      />
      <div id="sub-menu-wrap">
        <h5>Purchases</h5>
        <PurchaseMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content mb-5">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/purchases/purchase-bills">
                <ArrowLeftIcon />
              </NavLink>
              Add Credit Bill
            </h3>
          </div>

          <Space direction="vertical" style={{ width: "100%" }}>
            <Collapse
              collapsible="header"
              defaultActiveKey={["Supplier Details"]}
              items={[
                {
                  key: "Supplier Details",
                  label: "Supplier Details",
                  children: <SupplierDetail />,
                },
              ]}
            />
            <Collapse
              collapsible="header"
              defaultActiveKey={["Product Details"]}
              items={[
                {
                  key: "Product Details",
                  label: "Product Details",
                  children: <ProductDetail />,
                },
              ]}
            />
            <Form layout="vertical" form={SupplierForm} onFinish={handleSubmit}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item name="notes" label="Notes">
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={[24, 0]}>
                    <Col xs={24} md={12}>
                      <Form.Item name="subTotal" label="Sub Total">
                        <Input readOnly value={totalAmount} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="totalDiscount" label="Discount">
                        <Input readOnly defaultValue={totalDiscount} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="totalSaleTax" label="Sale Tax">
                        <Input readOnly defaultValue={totalSaleTax} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="overallDiscount"
                        label="OverAll Discount"
                        onChange={(e) => handleOverAllDiscountChange(e)}
                      >
                        <Input defaultValue={overallDiscount} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                      <h4>
                        <Form.Item
                          name="total"
                          label="Total"
                          rules={[
                            {
                              required: true,
                              message: "Please enter valid data",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </h4>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <Collapse
              collapsible="header"
              defaultActiveKey={["Add Payment"]}
              items={[
                {
                  key: "Add Payment",
                  label: "Add Payment",
                  children: <AddPayment />,
                },
              ]}
            />
            <Collapse
              collapsible="header"
              defaultActiveKey={["Expense Details"]}
              items={[
                {
                  key: "Expense Details",
                  label: "Expense Details",
                  children: <ExpenseDetail />,
                },
              ]}
            />
          </Space>
          <Form
            layout="vertical"
            className="my-5"
            form={SupplierForm}
            onFinish={handleSubmit}
          >
            <Row justify="end" className="text-end">
              <Col xs={24} md={4}>
                <Form.Item name="inComplete" valuePropName="checked">
                  <Checkbox
                    checked={Complete}
                    onChange={(e) => setComplete(!Complete)}
                  >
                    InComplete
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} md={{ span: 4, offset: 20 }}>
                <Dropdown.Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<DownOutlined />}
                  menu={{ items }}
                >
                  Approve and Close
                </Dropdown.Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddSalesInvoice;
