import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
  Flex,
} from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomerMenu from "./CustomerMenu";
import SalesMenu from "../Sales/SalesMenu";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import dayjs from "dayjs";
import CustomersDropdown from "../Common/CustomersDropdown";
import { PrinterOutlined, UploadOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";

const CustomerReport = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [CustomerForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [CustomerLoading, setCustomerLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [customerAccountCode, setCustomerAccountCode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false); // New state variable
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");

  const fetchCustomerTransactions = async (FormData) => {
    setLoading(true);
    setError(null);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetCustomerReportBy/${CompanyID}?customerAccountCode=${
          selectedCustomer ? selectedCustomer.accountNo : ""
        }&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${
          FormData.period
        }&includeAllCustomers=${FormData.customerName === "all"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      // console.log(response);
      setTransactions(response.data);
      setShowTable(true); // Show table after fetching data
    } catch (err) {
      console.error("Error fetching customer transactions:", err);
      setError("Failed to load transactions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Customer Report";
  }, []);

  const customer = CustomersDropdown();

  const handleSelectChange = async (value) => {
    const selectedCustomer = customer.find(
      (customer) => customer.value === value
    );
    if (selectedCustomer) {
      await fetchCustomerByID(selectedCustomer.value);
    }
  };

  const fetchCustomerByID = async (id) => {
    setCustomerLoading(true);
    try {
      const api_config = {
        method: "get",
        url: `${Config.base_url}CustomerSupplier/GetCustomerBy/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setSelectedCustomer(response.data.customerData);
      } else {
        setError("Customer not found.");
      }
    } catch (error) {
      console.error(
        "Error fetching customer data:",
        error.response?.data || error.message
      );
      setError("Failed to fetch customer data.");
    } finally {
      setCustomerLoading(false);
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date, dateString) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Suppliers");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Date", key: "date", width: 30 },
      { header: "Customer Name", key: "customerName", width: 30 },
      { header: "Details", key: "details", width: 20 },
      { header: "Ref No", key: "refNo", width: 10 },
      { header: "V. No", key: "voucherNo", width: 10 },
      { header: "Debit", key: "debit", width: 10 },
      { header: "Credit", key: "credit", width: 10 },
      { header: "Balance", key: "balance", width: 10 },
    ];

    // Add rows to the sheet
    transactions.forEach((customer, index) => {
      sheet.addRow({
        sr: index + 1,
        date: customer.date,
        customerName: customer.customerName,
        details: customer.details,
        refNo: customer.refNo,
        voucherNo: customer.voucherNo,
        debit: customer.debit,
        credit: customer.credit,
        balance: customer.balance,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `CustomerReport_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  let runningBalance = 0;

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      render: (text, record) => (
        <>
          <>{record.customerName.split(" (")[0]}</>
        </>
      ),
      sorter: (a, b) =>
        String(a.customerName).localeCompare(String(b.customerName)),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text) => `Sale ${text}`,
      sorter: (a, b) => String(a.details).localeCompare(String(b.details)),
    },
    {
      title: "Ref No",
      dataIndex: "refNo",
      key: "refNo",
      sorter: (a, b) => String(a.refNo).localeCompare(String(b.refNo)),
    },
    {
      title: "V. No",
      dataIndex: "voucherNo",
      key: "voucherNo",
      sorter: (a, b) => String(a.voucherNo).localeCompare(String(b.voucherNo)),
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => String(a.debit).localeCompare(String(b.debit)),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
      sorter: (a, b) => String(a.credit).localeCompare(String(b.credit)),
    },
    // Initialize running balance outside render

    {
      title: "Balance",
      dataIndex: "",
      key: "balance",
      render: (text, record, index) => {
        // Calculate the current record's balance
        const currentBalance = record.debit - record.credit;

        // Add the current record's balance to the running balance
        if (index === 0) {
          runningBalance = currentBalance; // For the first row
        } else {
          runningBalance += currentBalance; // Add to the running balance for subsequent rows
        }

        // Return the updated running balance
        return <>{runningBalance.toFixed(2)}</>;
      },
      sorter: (a, b) => String(a.balance).localeCompare(String(b.balance)),
    },
  ];

  const summary = () => {
    let totalDebit = 0;
    let totalCredit = 0;

    transactions.forEach(({ debit, credit }) => {
      totalDebit += debit || 0;
      totalCredit += credit || 0;
    });

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={6}>Total</Table.Summary.Cell>
        <Table.Summary.Cell>{totalDebit.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalCredit.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>
          {selectedCustomer !== null
            ? (
                totalDebit -
                totalCredit +
                selectedCustomer.customerBaseOpeningBalance
              ).toFixed(2)
            : ""}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const printTable = () => {
    const isAllCustomersSelected = selectedCustomer === "All Customers";

    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css' />"
    );
    printWindow.document.write("</head><body style='text-align: center;'>");
    printWindow.document.write(
      "<h1 style='text-align: center;'>Customer Report</h1>"
    );

    // Conditionally render customer name if "All Customers" is NOT selected
    if (!isAllCustomersSelected) {
      printWindow.document.write(
        "<h3>Customer: " +
          (selectedCustomer?.businessName || "All Customers") +
          "</h3>"
      );
      printWindow.document.write(
        "<h3>Account Code: " + (selectedCustomer?.accountNo || "N/A") + "</h3>"
      );
    } else if (isAllCustomersSelected) {
      printWindow.document.write("<h3>All Customers</h3>");
    }

    if (startDate && endDate) {
      printWindow.document.write(
        "<h3>Date Range: " + startDate + " - " + endDate + "</h3>"
      );
    }

    printWindow.document.write(
      "<div style='display: flex; justify-content: center;'>"
    );
    printWindow.document.write(document.querySelector(".ant-table").outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Sales</h5>
        <SalesMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/customer/manage">
                <ArrowLeftIcon />
              </NavLink>
              Customer Report
            </h3>
            {showTable && transactions.length > 0 && (
              <div className="header-actions">
                <Button
                  type="dashed"
                  onClick={handleExport}
                  icon={<UploadOutlined />}
                >
                  Export Report
                </Button>
                <Button
                  type="default"
                  onClick={printTable}
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </div>
            )}
          </div>

          <div className="filters-wrap">
            <Form onFinish={fetchCustomerTransactions} form={CustomerForm}>
              <Form.Item name="customerName">
                <Select
                  style={{ width: "300px" }}
                  onSelect={handleSelectChange}
                  placeholder="Select Customer"
                  showSearch // Enables the search input
                  optionFilterProp="label" // Specifies which prop should be filtered (label)
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option value="all">All Customers</Select.Option>
                  {customer &&
                    customer.map((customer) => (
                      <Select.Option
                        key={customer.value}
                        value={customer.value}
                        label={customer.label} // Ensure the label prop is passed
                      >
                        {customer.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="customerAccountCode"
                label="Customer Account Code"
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="period"
                onChange={(value) => setPeriod(value)}
                style={{ width: "150px" }}
                initialValue="last60Days"
              >
                <Select placeholder="Date Range">
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="startDate">
                <DatePicker
                  value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                  onChange={handleDateChange}
                />
              </Form.Item>
              <Form.Item name="endDate">
                <DatePicker
                  value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                  onChange={handleDueDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && transactions.length > 0 && (
            <>
              <div style={{ textAlign: "center", marginBottom: "16px" }}>
                <h3>
                  Customer:{" "}
                  {selectedCustomer
                    ? selectedCustomer.businessName
                    : "All Customers"}
                </h3>
                {selectedCustomer && (
                  <>
                    <h3>Account Code: {selectedCustomer.accountNo}</h3>
                    <h3>
                      Opening Balance:{" "}
                      {selectedCustomer.customerBaseOpeningBalance}
                    </h3>
                  </>
                )}
              </div>

              <Table
                columns={columns}
                dataSource={transactions}
                rowKey="id"
                summary={summary}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default CustomerReport;
