import {
    Form,
    Input,
    Select,
    Table,
    Button,
    Dropdown,
    Space,
    Menu,
    Popconfirm,
    message,
    Badge,
    DatePicker,
    Flex
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
    PlusOutlined,
    DownloadOutlined,
    UploadOutlined,
    MoreOutlined,
    EditOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { Link, NavLink } from "react-router-dom";
import SalesMenu from "./../SalesMenu";
import Config from "../../../Config";
import SubMenuToggle from "../../Common/SubMenuToggle";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { QueueListIcon } from "@heroicons/react/24/outline";

const ExcelJS = require("exceljs");

function IncompleteSaleInvoice() {
    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");

    const [loading, setLoading] = useState(false);
    const [ListOfRecords, setListOfRecords] = useState([]);
    const [OrderBy, setOrderBy] = useState("");
    const [Name, setName] = useState("");
    const [AccountCode, setAccountCode] = useState("");
    const [Type, setType] = useState("");
    const [Date, setDate] = useState("");
    const [CustomerID, setCustomerID] = useState("");

    const [IsDeleted, setIsDeleted] = useState(false);
    const [CustomerLoading, setCustomerLoading] = useState(false);
    const [open, setOpen] = useState(false);
    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [OpenDate, setOpenDate] = useState("");
    const [IncompleteSale, setIncompleteSale] = useState(0);


    const [form] = Form.useForm();
    const [formMain] = Form.useForm();

    const fetchSales = async () => {
        setLoading(true);
        const api_config = {
            method: "get",
            url: `${Config.base_url}SaleHead/GetBy/${CompanyID}?orderBy=${OrderBy}&customerAccountCode=${AccountCode}&customerName=${Name}&InComplete=true&saleType=${Type}&date=${Date}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${AccessKey}`,
            },
        };

        try {
            const response = await axios(api_config);
            if (response.data && response.data.status_code === 1) {
                setListOfRecords(response.data.listofSales || []);
            } else {
                setListOfRecords([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setListOfRecords([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        document.title = "Incomplete Sales Invoices";
        fetchSales();
    }, [OrderBy, AccountCode, Name, Type, Date]);

    const handleFilters = (formData) => {
        //console.log("Form Data Submitted:", formData);
        setAccountCode(formData["customerAccountCode"] || "");
        setName(formData["customerName"] || "");
        setOrderBy(formData["OrderBy"] || "");
        setType(formData["saleType"] || "");
        setDate(OpenDate);
        //console.log("Type State after set:", formData["saleType"]);
        // fetchAccounts();
    };

    const columns = [
        {
            title: "Sr#",
            dataIndex: "",
            key: "SR",
            render: (_, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },

        {
            title: "A/C No.",
            dataIndex: "customerAccountCode",
            key: "customerAccountCode",
        },

        {
            title: "Customer",
            dataIndex: "customerName",
            key: "customerName",
            render: (text, record) => (
                record.customerName.split('(')[0]
            )
        },

        {
            title: "Type",
            dataIndex: "saleType",
            key: "saleType",
        },

        {
            title: "Inv. No.",
            dataIndex: "invoiceNo",
            key: "invoiceNo",
            render: (text, record) => (
                <>
                    <>
                        <NavLink
                            className={"primary"}
                            to={
                                record.saleType === "Invoice"
                                    ? `/sales/sales-invoices/edit-sales-invoices/${record.invoiceNo}`
                                    : `/sales/sales-invoices/edit-credit-note/${record.invoiceNo}`
                            }
                        >
                            {record.invoiceNo}
                        </NavLink>
                    </>
                </>
            ),
        },

        {
            title: "Doc No.",
            dataIndex: "docNo",
            key: "docNo",
        },

        {
            title: "Total",
            dataIndex: "",
            key: "total",
            render: (text, record) => (
                <>
                    {record.saleType === "Invoice" ? (
                        <>{record.total}</>
                    ) : (
                        <>{-record.total}</>
                    )}
                </>
            ),
        },

        {
            title: "Balance",
            dataIndex: "",
            key: "balance",
            render: (text, record) => (
                <>
                    {record.saleType === "Invoice" ? (
                        <>{record.balance}</>
                    ) : (
                        <>{-record.balance}</>
                    )}
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "Actions",
            key: "Actions",
            render: (_, record) => (
                <div className="table-actions">
                    <NavLink
                        className={"primary"}
                        to={
                            record.saleType === "Invoice"
                                ? `/sales/sales-invoices/edit-sales-invoices/${record.invoiceNo}`
                                : `/sales/sales-invoices/edit-credit-note/${record.invoiceNo}`
                        }
                    >
                        <EditOutlined />
                    </NavLink>
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this account?"
                        onConfirm={
                            record.saleType === "Invoice"
                                ? (e) => deleteInvoiceSale(record.id)
                                : (e) => deleteCreditSale(record.id)
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const deleteInvoiceSale = async (sno) => {
        setLoading(true);
        try {
            const accountToUpdate = ListOfRecords.find((u) => u.id === sno);
            //console.log(accountToUpdate);

            const name = accountToUpdate.customerName.match(/^[^\(]+/)[0].trim();
            const code = accountToUpdate.customerAccountCode;

            const CustomerData = await axios.get(
                `${Config.base_url}CustomerSupplier/GetCustomersBy/${CompanyID}?businessName=${name}&accountCode=${code}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            //console.log(CustomerData.data.listofCustomers[0]);

            const deleteCustomer = CustomerData.data.listofCustomers[0];
            if (!accountToUpdate) {
                message.error("Sale not found!");
                return;
            }
            //console.log(accountToUpdate);

            if (accountToUpdate.balance === 0) {
                message.error("Paid invoices can't be deleted")
            }
            else {

                const updatedAccount = {
                    ...accountToUpdate,
                    isActive: false,
                    isDeleted: true,
                };

                const response = await axios.patch(
                    `${Config.base_url}SaleHead/UpdateRecord/${sno}`,
                    updatedAccount,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${AccessKey}`,
                        },
                    }
                );
                if (response.data.status_code == 1) {
                    const updateCustomerData = {
                        ...deleteCustomer,
                        customerOpeningBalance:
                            deleteCustomer.customerOpeningBalance - accountToUpdate.balance,
                    };

                    await axios.patch(
                        Config.base_url + `CustomerSupplier/UpdateRecord/${deleteCustomer.id}`,
                        updateCustomerData,
                        {
                            headers: {
                                Authorization: `Bearer ${AccessKey}`,
                            },
                        }
                    );
                    message.success("Invoice deleted Successfully");
                }
            }
        } catch (error) {
            //console.log(error);
            message.error("Error in Deleting invoice");
        }
        fetchSales();
    };

    const deleteCreditSale = async (sno) => {
        setLoading(true);
        try {
            const accountToUpdate = ListOfRecords.find((u) => u.id === sno);
            //console.log(accountToUpdate);

            const name = accountToUpdate.customerName.match(/^[^\(]+/)[0].trim();
            const code = accountToUpdate.customerAccountCode;

            const CustomerData = await axios.get(
                `${Config.base_url}CustomerSupplier/GetCustomersBy/${CompanyID}?businessName=${name}&accountCode=${code}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            //console.log(CustomerData.data.listofCustomers[0]);

            const deleteCustomer = CustomerData.data.listofCustomers[0];
            if (!accountToUpdate) {
                message.error("Sale not found!");
                return;
            }
            //console.log(accountToUpdate);

            if (accountToUpdate.balance === 0) {
                message.error("Paid credits can't be deleted")
            }
            else {

                const updatedAccount = {
                    ...accountToUpdate,
                    isActive: false,
                    isDeleted: true,
                };

                const response = await axios.patch(
                    `${Config.base_url}SaleHead/UpdateRecord/${sno}`,
                    updatedAccount,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${AccessKey}`,
                        },
                    }
                );
                if (response.data.status_code == 1) {
                    const updateCustomerData = {
                        ...deleteCustomer,
                        customerOpeningBalance:
                            deleteCustomer.customerOpeningBalance + accountToUpdate.balance,
                    };

                    await axios.patch(
                        Config.base_url + `CustomerSupplier/UpdateRecord/${deleteCustomer.id}`,
                        updateCustomerData,
                        {
                            headers: {
                                Authorization: `Bearer ${AccessKey}`,
                            },
                        }
                    );
                    message.success("Credit Note deleted Successfully");
                }
            }
        } catch (error) {
            //console.log(error);
            message.error("Error in Deleting invoice");
        }
        fetchSales();
    };

    const onReset = () => {
        form.resetFields();
        setType("");
        setAccountCode("");
        setName("");
        setDate("");
        setOrderBy("");
        setOpenDate("");
    };

    const items = [
        {
            key: "1",
            label: (
                <Link to={`/sales/sales-invoices/add-sales-invoices`}>
                    Sales Invoice (SI)
                </Link>
            ),
        },
        {
            key: "2",
            label: (
                <Link to={`/sales/sales-invoices/add-credit-note`}>
                    Credit Note (SC)
                </Link>
            ),
        },
        {
            key: "3",
            label: (
                <Link to={`/sales/sales-invoices/batch-invoice`}>
                    Batch Invoice (SI)
                </Link>
            ),
        },
        {
            key: "4",
            label: (
                <Link to={`/sales/sales-invoices/bulk-invoicing`}>
                    Bank Invoice (SI)
                </Link>
            ),
        },
    ];

    const handleExport = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Sales");

        // Set column headers and their widths
        sheet.columns = [
            { header: "Sr#", key: "sr", width: 10 },
            { header: "Date", key: "date", width: 20 },
            { header: "Account Number", key: "customerAccountCode", width: 30 },
            { header: "Customer Name", key: "customerName", width: 30 },
            { header: "Type", key: "saleType", width: 20 },
            { header: "Invoice Number", key: "invoiceNo", width: 20 },
            { header: "Doc No", key: "docNo", width: 20 },
            { header: "Total", key: "total", width: 15 },
            { header: "Balance", key: "balance", width: 15 },
        ];

        // Add rows to the sheet
        ListOfRecords.forEach((sales, index) => {
            sheet.addRow({
                sr: index + 1,
                date: sales.date,
                customerAccountCode: sales.customerAccountCode,
                customerName: sales.customerName,
                saleType: sales.saleType,
                invoiceNo: sales.invoiceNo,
                docNo: sales.docNo,
                total: sales.total,
                balance: sales.balance,
            });
        });

        const now = new window.Date();
        const dateString = now
            .toLocaleString("sv-SE", { timeZoneName: "short" }) // Using Swedish locale for formatting
            .replace(/[^0-9]/g, ""); // Removing non-numeric characters

        // Generate the Excel file and prompt the user to download it
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `SalesList_${dateString}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const handleDateChange = (e, value) => {
        setOpenDate(value);
    };

    const sortedData = ListOfRecords.sort(
        (a, b) => dayjs(b.date).unix() - dayjs(a.date).unix()
    );

    return (
        <>
            <div id="sub-menu-wrap">
                <h5>Sales</h5>
                <SalesMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header"><SubMenuToggle />
                        <h3 className="page-title">
                            <NavLink to="/sales/sales-invoices">
                                <ArrowLeftIcon />
                            </NavLink>
                            Incomplete Sales Invoices</h3>
                        <div className="header-actions">
                            <Button
                                type="dashed"
                                onClick={handleExport}
                                icon={<UploadOutlined />}
                            >
                                Export
                            </Button>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                placement="bottomLeft"
                                arrow
                            >
                                <Button type="primary" icon={<PlusOutlined />}>
                                    New
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="filters-wrap">
                        <Flex justify="space-between" align="center">
                            <Form onFinish={handleFilters} form={form}>
                                <Form.Item name="OrderBy">
                                    <Select placeholder="Order By" style={{ width: 120 }}>
                                        <Select.Option value="customeraccountcode">
                                            A/C Code
                                        </Select.Option>
                                        <Select.Option value="customername">
                                            Customer Name
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="saleType">
                                    <Select placeholder="Type" style={{ width: 120 }}>
                                        <Select.Option value="">All Types</Select.Option>
                                        <Select.Option value="Credit">Credit</Select.Option>
                                        <Select.Option value="Invoice">Invoice</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item name="customerName">
                                    <Input onFocus={(e) => e.target.select()} placeholder="Customer Name" />
                                </Form.Item>
                                <Form.Item name="customerAccountCode">
                                    <Input onFocus={(e) => e.target.select()} placeholder="A / C No" />
                                </Form.Item>
                                <Form.Item name="date">
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        onChange={handleDateChange}
                                        placeholder="Date"
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>

                                <Button htmlType="submit" type="primary">
                                    Filter
                                </Button>
                                <Button htmlType="button" onClick={onReset} type="link">
                                    Reset
                                </Button>
                            </Form>
                        </Flex>
                    </div>

                    <Table
                        scroll={{
                            x: "100%",
                        }}
                        columns={columns}
                        dataSource={sortedData}
                        size="small"
                        loading={loading}
                        pagination={true}
                    />
                </div>
            </div>
        </>
    );
}

export default IncompleteSaleInvoice;
